import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';

import * as service from '../../../Service/service';


class UserModal extends Component {

    state = {
        operation:'Select operation',
        password:'',
        confirmPassword:'',
        isAdjusting:false,
        showInfo:false,
        infoType:'alert alert-dismissible mt-2 fade show alert-danger',
        kycDocList:[],
        showDocuments: false
    }

    onChoiceSelect = (event) => {
        this.setState({
            ...this.state,
            operation: event.target.value,
            showDocuments:false
        }, () => {
            console.log(this.state);
        });
    }

    onPasswordEnter=(e)=>{
        this.setState({
            ...this.state,
            password: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onConfirmPassword=(e)=>{
        this.setState({
            ...this.state,
            confirmPassword: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    getUserDocuments=(username)=>{
        console.log(username);
        service.getKycDocuments(username).then((response) => {
            console.log(response)
            if(response.data.length!=0){
                this.setState({
                    ...this.state,
                    kycDocList: response.data,
                    showDocuments: true,
                });
            }
        }, (error) => {
            console.log(error);
        });
    }

    changePassword=()=>{
        this.setState({...this.state,isAdjusting: true})

        if(this.state.password !== this.state.confirmPassword){
            this.setState({
                ...this.state,
                isAdjusting:false,
                message: 'Passwords not matching. Kindly check and re-try.',
                showInfo:true,
            });
        }else if(this.state.password.length < 6){
            this.setState({
                ...this.state,
                isAdjusting:false,
                showInfo: true,
                message: "Passwords length must be at-least 6 characters.",
                
            });
        }
        else{

            let obj = {
                password:this.state.password,
                confirmPassword:this.state.confirmPassword,
                username:this.props.basicModal.data.username
            }

            if(this.state.confirmPassword === this.state.password){

            service.updateUserPassword(obj).then((response)=>{
                this.setState({
                    ...this.state,
                    isAdjusting:false,
                    showInfo: true,
                });

                if(response.data){
                    this.setState({
                        message : 'Password Updated Successfully',
                        infoType : 'alert alert-dismissible mt-2 fade show alert-success',
                        operation:'Select operation'
                    })
                    this.props.updateUserComponentTrue();
                }
                if(!response.data){
                    this.setState({
                        message : 'Something went wrong',
                        infoType : 'alert alert-dismissible mt-2 fade show alert-danger',
                        operation:'Select operation'
                    })
                }
            
        }, (error) => {

            console.log(error);
            this.setState({
                ...this.state,
                infoType: 'alert alert-dismissible mt-2 fade show alert-danger',
                message: "Something went wrong. Please try again after sometime.",
                showInfo: true,
                isAdjusting:false,
                operation:'Select operation'
            });
        });
    }
    else {
        this.setState({
            ...this.state,
            infoType: 'alert alert-dismissible mt-2 fade show alert-danger',
            message: "Passwords did not match",
            showInfo: true,
            isAdjusting:false,
            operation:'Select operation'
        });
    }
    }
}


    viewUserRoute=()=>{
        if(this.state.operation=='View Addresses'){
        window.location.href= window.location.origin+"/view/address/"+this.props.basicModal.data.username
        }
    }

    componentWillReceiveProps(){
        this.setState({operation:'Select operation',password:'',confirmPassword:'', showDocuments:false})    
    }

    render() {
        return (
            <Modal visible={this.props.basicModal.showModal} onClickBackdrop={this.props.closeUserModal}>
                <div className="modal-header">
                    <h5 className="modal-title">What do you want to do?</h5>
                </div>
                <div className="modal-body">

                    <div className="col-12">
                        <div className="alert alert-secondary" role="alert">
                            
                                <b>Username : {this.props.basicModal.data.username}</b><br/>
                                
                        </div>
                    
                            <label htmlFor="choice" className="font-weight-bold">Select Operation</label>
                            <select value={this.state.operation} onChange={this.onChoiceSelect}  className="browser-default custom-select" id="choice" placeholder="Select operation">
                                <option>Select operation</option>
                                <option>View Addresses</option>
                                <option>Change Password</option>
                                <option>View Documents</option>
                                <option>Upload Documents</option>
                            </select>
                            {
                                this.state.operation=='View Addresses' && this.viewUserRoute()
                            }
                            {
                                this.state.operation=='Change Password' && (
                                    <div>
                                       {
                                                this.state.showInfo && (
                                                    <div className={this.state.infoType} role="alert">
                                                        <span>{this.state.message}</span>
                                                    </div>
                                                )
                                        }
                                            <form>
                                                
                                                <label htmlFor="amount" className="mt-2">Password</label><br/>
                                                <input type="password" style={{border:'1px solid #D3D3D3',width: '100%'}} onChange={this.onPasswordEnter} value={this.state.password}  placeholder="Enter Password"/> 
                                                <br />
                                                <div className="mt-2">
                                                    <label htmlFor="comments" className="">Confirm Password</label><br/>
                                                    <input type="password" style={{border:'1px solid #D3D3D3',width: '100%'}} onChange={this.onConfirmPassword} value={this.state.confirmPassword}  placeholder="Confirm Password"/> 
                                                </div>
                                            </form>
                                                
                                    </div>
                                )
                            }

                            {
                                this.state.operation=='View Documents' && (
                                    <div className='mt-2'>
                                            <button  type="button" className="btn btn-success" disabled={this.state.isAdjusting} onClick={()=> this.getUserDocuments(this.props.basicModal.data.username)}>
                                                View Documents
                                            </button>
                                            <br/>
                                            {
                                            this.state.showDocuments && (
                                                <div>
                                                {this.state.kycDocList.map(docs => (
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td><b>{docs.documentType}</b></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><a className='text-primary' style={{cursor:'pointer'}} href={docs.documentUrl}>View Image</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                ))}
                                                </div>
                                            )
                                            }
                                    </div>
                                )
                            }

                            {
                                this.state.operation=='Upload Documents' && (
                                    <div className='mt-2'>
                                        {console.log(this.props.basicModal.data.username)}
                                            <a  type="button" className="btn btn-success" href={'/upload/' + this.props.basicModal.data.username}>
                                                Upload Documents
                                            </a>
                                    </div>
                                )
                            }
                            
                    </div>

                </div>
                <div className="modal-footer">
                    {
                        this.state.operation=='Change Password' && (
                    <button  type="button" className="btn btn-success" disabled={this.state.isAdjusting} onClick={()=> this.changePassword()}>
                        Change Password
                    </button>)
                    }

                    <button type="button" className="btn btn-primary" onClick={this.props.closeUserModal}>
                        Close
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        basicModal: currentState.basicModal,
        isModalChanged: currentState.isModalChanged,
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeUserModal: () => {
            dispatch({ type: 'basic.modal.close' })
        },
        updateUserComponentTrue: () =>{
            dispatch({type: 'modal.changed.true'})
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(UserModal);