import React, { Component } from 'react';
import AccountHeader from './../Generic/Header/AccountHeader';
import BreadCrumbs from './../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import ChangePasswordComponent from './ChangePassword/ChangePasswordComponent';
import HomeComponent from './Home/HomeComponent';
import OrderComponent from './Order/OrderComponent';
import UserComponent from './UserDetailComponent/UserComponent';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import AllInvoiceComponent from './Invoice/AllInvoiceComponent';
import OrderExpiryComponent from './OrderExpiry/OrderExpiryComponent';
import ReportComponent from './Report/ReportComponent';
import AllPaymentComponent from './Payments/AllPaymentComponent';
import AddProductComponent from './AddProduct/AddProductComponent';
import ProductOperation from './ProductOperations/ProductOperations';
import IssueListComponent from './IssueList/IssueListComponent';
import FooterComponent from '../Generic/Footer/FooterComponent';

class AccountWrapper extends Component {

    state = {
        currentComponent: 'Home'
    }

    onComponentChange = (comp) => {

        this.setState({
            ...this.state,
            currentComponent: comp
        });
    }

    componentDidMount() {
        var decoded = jwt_decode((localStorage.keycloak))
        if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
            alert("Unauthorized access");
            localStorage.removeItem('keycloak')
            window.location.href=window.location.origin
        }

        else if(window.location.search.length>1){
            var urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('page')){
                
                if(urlParams.get('page') == 'order'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Orders' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                else if(urlParams.get('page') == 'invoice'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Invoices' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                else if(urlParams.get('page') == 'issue'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Issues' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                else if(urlParams.get('page') == 'user'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Users' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                else if(urlParams.get('page') == 'orderExpiry'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'OrderExpiry' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                else if(urlParams.get('page') == 'report'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Report' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                else if(urlParams.get('product') == 'product'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Add Product' 
                    }, () => {
                        this.renderComponent();
                    })
                }
            }
        }
    }

    renderComponent = () => {

        this.props.updateBreadcumbs({
            navigation : [{ name: 'Dashboard', link: './account' }, { name: this.state.currentComponent, link: '#' }],
            showSidebar : false,
            sidebar : []
        });

        let renderMe = null;

        switch (this.state.currentComponent) {
            case 'Home': renderMe = (<HomeComponent />); break;
            case 'Orders': renderMe = (<OrderComponent />); break;
            case 'Users': renderMe = (<UserComponent />); break;
            case 'Password': renderMe = (<ChangePasswordComponent />); break;
            case 'Invoices': renderMe = (<AllInvoiceComponent />); break;
            case 'Issues': renderMe = (<IssueListComponent />); break;
            case 'OrderExpiry': renderMe = (<OrderExpiryComponent />); break;
            case 'Report': renderMe = (<ReportComponent />); break;
            case 'Payments': renderMe = (<AllPaymentComponent />); break;
            case 'ProductManagement': renderMe = (<AddProductComponent />); break;
            case 'ProductOperation': renderMe = (<ProductOperation />); break;
            default: renderMe = (<HomeComponent />);
        }

        return renderMe;
    }

    render() {
        return (
            <React.Fragment>
                <AccountHeader handleMenuClick={this.onComponentChange} />

                <BreadCrumbs />

                <main role="main" className="container">
                    {this.renderComponent()}
                </main>
                <FooterComponent />
            </React.Fragment>

        );
    }
}


const mapStateToProps = (currentState) => {
    return { };    
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(AccountWrapper);