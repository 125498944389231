import React, { Component } from "react";
import "./../../styles/invoice.css";

import * as service from "./../../Service/service";
import * as jwt_decode from "jwt-decode";
import keys from "./../../Config/config.json";

class OrderSummaryComponent extends Component {
  state = {
    orderNumber: "",
    orderInfo: {},
    slugs: [],
    address: {},
    showData: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      orderNumber: props.match.params.orderNumber,
    };
  }

  componentWillMount() {
    if (localStorage.hasOwnProperty("keycloak") == false) {
      alert("Login to view");
      window.location.href = window.location.origin + "?url=" + window.location;
    } else if (localStorage.hasOwnProperty("keycloak") == true) {
      this.orderInfoByOrderNumber();
    }
  }

  checkAddress = () => {
    service.checkAddress(this.state.orderInfo.addressId).then(
      (response) => {
        this.setState(
          {
            ...this.state,
            address: response.data,
            showData: true,
          },
          () => {
            console.log(this.state);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  mergeProductInfo = () => {
    let tmpState = JSON.parse(JSON.stringify(this.state));

    service.cartProductInfo(this.state.slugs).then(
      (response) => {
        response.data.map((prod) => {
          tmpState.orderInfo.productList.map((item) => {
            if (item.productSlug === prod.slug) {
              item.productInfo = prod;
            }
          });
        });

        this.setState(tmpState, () => {
          this.checkAddress();
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  orderInfoByOrderNumber = () => {
    service.orderInfoByOrderNumber(this.state.orderNumber).then(
      (response) => {
        var decoded = jwt_decode(localStorage.keycloak);
        if (JSON.parse(decoded.preferred_username != keys.admin_token)) {
          alert("Unauthorized access");
          localStorage.removeItem("keycloak");
          window.location.href =
            window.location.origin + "?url=" + window.location;
        } else if (response.data.length != 0) {
          var slugs = [];
          response.data.productList.map((elem) => {
            slugs.push(elem.productSlug);
          });
          var orderDetails = response.data;
          orderDetails.productList = orderDetails.productList.sort((a, b) =>
            a.productId !== b.productId
              ? a.productId < b.productId
                ? -1
                : 1
              : 0
          );

          orderDetails.securityDeposit = orderDetails.securityDeposit.sort(
            (a, b) =>
              a.productId !== b.productId
                ? a.productId < b.productId
                  ? -1
                  : 1
                : 0
          );

          this.setState(
            {
              ...this.state,
              orderInfo: orderDetails,
              slugs: slugs,
            },
            () => {
              this.mergeProductInfo();
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  statusColor = (orderStatus) => {
    if (orderStatus == "DELIVERED") {
      return "text-success ml-3";
    } else if (
      orderStatus == "PAYMENT_AWAITED" ||
      orderStatus == "ENQUIRY_AWAITED"
    ) {
      return "text-warning ml-3";
    } else {
      return "text-danger ml-3";
    }
  };
  printPage() {
    var printButton = document.getElementById("printpagebutton");

    //Set the button visibility to 'hidden'
    printButton.style.visibility = "hidden";

    //Print the page content
    window.print();

    //Restore button visibility
    printButton.style.visibility = "visible";
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showData && (
          <div className="body p-3 mt-5">
            <div className="float-right">
              <a
                style={{ cursor: "pointer" }}
                id="printpagebutton"
                className="text-primary"
                onClick={() => this.printPage()}
              >
                Click to Print
              </a>
            </div>
            <br />
            <div className="row mt-4">
              <div className="col-12 text-center">
                <img
                  width="180px"
                  src="https://voorent-new.s3.ap-south-1.amazonaws.com/application/voorent.png"
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7">
                <span style={{ position: "relative", left: "-15px" }}>
                  <span className="font-weight-bold">
                    {this.state.address.name}
                  </span>{" "}
                  <br />
                  {this.state.address.houseNo} {this.state.address.address1}
                  <br />
                  {this.state.address.address2} <br />
                  {this.state.address.city} {this.state.address.pincode}
                  <br />
                  Contact : {this.state.address.contactNo}
                  <br />
                </span>

                <div className="row mt-3">
                  <div
                    className="col-12 font-weight-bold"
                    style={{ background: "#DDD", padding: "10px" }}
                  >
                    <span>Order Number :</span>
                    <span> {this.state.orderInfo.orderNumber}</span>
                    <span
                      className={this.statusColor(
                        this.state.orderInfo.orderStatus
                      )}
                    >
                      {this.state.orderInfo.orderStatus}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-4 offset-1 mt-auto p-0">
                <table className="table table-bordered mb-0">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Placed On</td>
                      <td>
                        {new Date(
                          this.state.orderInfo.orderDate
                        ).toLocaleDateString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div
                className="col-12 font-weight-bold text-center mt-3"
                style={{
                  background:
                    "linear-gradient(270deg, rgb(232, 85, 78), rgb(1, 73, 127))",
                  color: "white",
                  padding: "10px",
                }}
              >
                <span>Your Order Details</span>
              </div>

              <div className="col-12 p-0 mt-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Items</th>
                      <th>Duration</th>
                      <th>Quanity</th>
                      <th>Rent (Excl.GST)</th>
                      <th>SGST</th>
                      <th>CGST</th>
                      <th>Rent (Incl.GST)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orderInfo.productList.map((item, key) => (
                      <tr>
                        <td>{item.productInfo.name}</td>
                        <td>{item.tenure} Months</td>
                        <td>{item.quantity}</td>
                        <td>
                          Rs.{" "}
                          {this.state.orderInfo.isMedical
                            ? this.state.orderInfo.securityDeposit[key].rent
                            : (
                                parseFloat(
                                  this.state.orderInfo.securityDeposit[key].rent
                                ) / 1.18
                              ).toFixed(2)}
                        </td>
                        <td>
                          Rs.{" "}
                          {(
                            (parseFloat(
                              this.state.orderInfo.securityDeposit[key].rent
                            ) /
                              1.18 /
                              100) *
                            9
                          ).toFixed(2)}
                        </td>
                        <td>
                          Rs.{" "}
                          {(
                            (parseFloat(
                              this.state.orderInfo.securityDeposit[key].rent
                            ) /
                              1.18 /
                              100) *
                            9
                          ).toFixed(2)}
                        </td>
                        <td>
                          Rs.{" "}
                          {this.state.orderInfo.isMedical
                            ? (
                                parseFloat(
                                  this.state.orderInfo.securityDeposit[key].rent
                                ) +
                                this.state.orderInfo.securityDeposit[key].rent *
                                  0.18
                              ).toFixed(2)
                            : parseFloat(
                                this.state.orderInfo.securityDeposit[key].rent
                              ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="col-12 font-weight-bold text-center"
                style={{
                  background:
                    "linear-gradient(270deg, rgb(232, 85, 78), rgb(1, 73, 127))",
                  color: "white",
                  padding: "10px",
                }}
              >
                <span>Billing Details</span>
              </div>

              <div className="col-5 p-0 mt-3">
                <span className="font-weight-bold">Deposit & Monthly Bill</span>
                <table className="table table-bordered mt-2">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Total Deposit</td>
                      <td>
                        Rs.{" "}
                        {this.state.orderInfo.orderTerms.totalDeposit.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Monthly Bill</td>
                      <td>
                        Rs.{" "}
                        {this.state.orderInfo.orderTerms.monthlyBillAmount.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-6 offset-1 p-0 mt-3">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Total Amount</td>
                      <td>
                        Rs.{" "}
                        {(this.state.orderInfo.orderTerms.monthlyBillAmount + this.state.orderInfo.orderTerms.discount).toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">
                        Discount
                        <span className="text-black-50">
                          {" "}
                          ( {this.state.orderInfo.couponId} )
                        </span>
                      </td>
                      <td>
                        {" "}
                        Rs. -
                        {this.state.orderInfo.orderTerms.discount.toFixed(
                          2
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Final Amount</td>
                      <td>
                        Rs.{" "}
                        {this.state.orderInfo.orderTerms.monthlyBillAmount.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Deposit Amount </td>
                      <td>
                        Rs.{" "}
                        {this.state.orderInfo.orderTerms.totalDeposit.toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Total Paid</td>
                      <td>
                        Rs.{" "}
                        {(this.state.orderInfo.orderTerms.monthlyBillAmount + this.state.orderInfo.orderTerms.totalDeposit).toFixed(
                          2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mb-5 mt-3">
              <div
                className="col-12 text-black-50"
                style={{ background: "rgb(245, 243, 243)", padding: "12px" }}
              >
                <span>
                  NOTE: Security deposit will get returned after order tenure is
                  over & items are picked up back by Voorent team.
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                {/* <span className="text-black-50">
                  Kindly pay the amount before due date to avoid late fee
                  charges. Due is 15 days from date of generation of invoice.
                </span> */}
                <table className="m-auto">
                  <tr>
                    <td>
                      <a
                        target="_blank"
                        className="font-weight-bold"
                        style={{ color: "#00487f" }}
                        href="https://voorent.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        className="font-weight-bold"
                        style={{ color: "#00487f" }}
                        href="https://voorent.com/terms-conditions"
                      >
                        Terms And Conditions
                      </a>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        className="font-weight-bold"
                        style={{ color: "#00487f" }}
                        href="https://voorent.com/faq"
                      >
                        FAQ
                      </a>
                    </td>
                  </tr>
                </table>

                <table className="m-auto">
                  <tr>
                    <td>
                      <a href="https://www.facebook.com/Voorent.rentals">
                        <img
                          width="32px"
                          src="https://voorent-new.s3.ap-south-1.amazonaws.com/images/facebook.png"
                        />
                      </a>
                    </td>
                    <td>
                      <a href="https://www.linkedin.com/company/voorent?originalSubdomain=in">
                        <img
                          width="32px"
                          src="https://voorent-new.s3.ap-south-1.amazonaws.com/images/linkedin.png"
                        />
                      </a>
                    </td>
                  </tr>
                </table>

                <span className="display-block">
                  Copyright © 2017 - 2020 Voorent Pvt. Ltd.™ All Rights Reserved
                </span>
                <span className="display-block">
                  Basement, 258, Sector 27, Gurugram, Haryana 122022
                </span>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default OrderSummaryComponent;
