import React from 'react';
import  _  from 'lodash';
import PropTypes from 'prop-types'; 

const Pagination = props => {

    const { itemsCount,pageSize,onPageChange ,currentPage,pageLoad} =props;
    const pagesCount = Math.ceil(itemsCount / pageSize); //Math.ceil convert to integer
    if(pagesCount===1) return null;

    return(
    <nav>
        <ul className="pagination" style={{cursor:'pointer'}}>
        <li key={0} className="page-item">
            <button disabled={pageLoad || currentPage==1}  className="page-link" onClick={() => onPageChange(1)}>First</button>
        </li>    
        <li key={1} className="page-item">
            <button disabled={pageLoad || currentPage==1} className="page-link" onClick={() => (currentPage-1)>=1?onPageChange(currentPage-1):onPageChange(currentPage)}>Prev</button>
        </li>
        <li key={2} className='page-item active'> 
            <button disabled={pageLoad} className="page-link">{currentPage}</button>
        </li>

        <li key={3} className="page-item">
            <button disabled={pageLoad || currentPage==pagesCount}  className="page-link" onClick={() => onPageChange(((currentPage+1)<=pagesCount)?currentPage+1:currentPage)}>Next</button>
        </li>
        <li key={4} className="page-item">
            <button disabled={pageLoad || currentPage==pagesCount} className="page-link" onClick={() => onPageChange(pagesCount)}>Last</button>
        </li>
        </ul>
    </nav>
    );
};

Pagination.propTypes = {
    itemsCount:PropTypes.number.isRequired,
    pageSize:PropTypes.number.isRequired,
    onPageChange:PropTypes.func.isRequired ,
    currentPage:PropTypes.number.isRequired
};
 
export default Pagination;