import React, { Component } from 'react';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';
import AccountHeader from '../../Generic/Header/AccountHeader';
import H2Title from './../../Generic/Element/Heading';
import * as jwt_decode from 'jwt-decode';
import keys from './../../../Config/config.json';
import { connect } from 'react-redux';
import * as service from '../../../Service/service';

class ProductOperation extends Component {
    state = { 
        choice: '',
        isLoaded:false,
        productName:'',
        products:[],
        message:'',
        showMessage:false,

     }

    goBack=()=>{
        this.setState({
            choice: '',
            isLoaded:false,
            productName:'',
            products:[],
            message:'',
            showMessage:false,
        })
    }
    onChoiceSelect=(e)=>{
        this.setState({
            ...this.state,
            products:[],
            isLoaded:false,
            showMessage:false,
            message:'',
            choice: e.target.value,
        }, () => {
            this.getProducts();
            console.log(this.state);
        });

    }

    getProducts=()=>{
        service.listProductByChoice(this.state.choice).then((response)=>{
            if(response.data){
            this.setState({
                products : response.data,
                isLoaded :true
            }, () => {
                console.log(this.state);
            })
        }
    })
    
    }
    updateProduct=()=>{
        service.updateProductByChoice(this.state.choice,this.state.productName).then((response)=>{
            if(response.data){
            this.setState({
                showMessage :true,
                message:"Operation performed successfully!"
            }, () => {
                this.getProducts();
                console.log(this.state);
            })
        }
    })
    }
    
    onProductSelect=(e)=>{
        this.setState({
            ...this.state,
            productName: e.target.value,
            isDisabled:false
        }, () => {
            
            console.log(this.state);
        });
    }

    componentDidMount(){

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Report', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
    }

    render() { 
        return ( 
            <React.Fragment>
            <AccountHeader/>
            <BreadCrumbs/>
            <div className='container mt-3 mb-3'>
                           
                           <div className="row mt-5" style={{ height: '100%' }}>
                               <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                            </div>
       
                            <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>
                                        <H2Title value="Product Operations" /><br/>
                                    <div className='container' >

                                    {
                                        this.state.showMessage && (
                                            <div className="col-12">
                                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                    {this.state.message}
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                    { this.state.choice==='' && (
                                        <div>
                                        <label htmlFor="choice" className="font-weight-bold mt-2">Operation </label>
                                        <select value={this.state.choice} onChange={this.onChoiceSelect}  className="browser-default custom-select mb-2" id="choice" placeholder="Select the choice">
                                            <option value=''>Select the choice</option>
                                            <option>Product Out Of Stock</option>
                                            <option>Mark Product Available</option>
                                            <option>Deactivate Product</option>
                                        </select>
                                        </div>
                                    )
                                    }
                                        {
                                         this.state.choice!=='' && !this.state.isLoaded && (
                                            <div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                            <div className="row">
                                                <div className="col-12 text-center mt-5">
                                                    <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <span className="pl-3 text-black-50 d-block" >Loading Products . . </span>
                                                </div>
                                            </div>
                                        </div>
                                         )
                                        }

                                        {this.state.choice!=='' && this.state.isLoaded && (
                                            <div>
                                            <label htmlFor="choice" className="font-weight-bold mt-2">Product </label>
                                            <select value={this.state.productName} onChange={this.onProductSelect}  className="browser-default custom-select mb-2" id="choice" placeholder="Select the choice">
                                                <option value='' disabled selected>Select Product</option>
                                                {this.state.products.map((product,iKey) => (
                                                    <option key={iKey} value={product.name}>{product.name}</option>
                                                ))}
                                            </select>
                                            <button className="btn btn-primary col-6 mt-2" onClick={this.updateProduct}>Confirm Operation</button>

                                            <button className="btn btn-primary col-5 ml-4 mt-2" onClick={this.goBack}>Reset</button>
                                            </div>
                                        )}
                                     
                                    </div>

                            </div>
                           </div>
                           
                               
                           </div>
                           

        </React.Fragment>  
         );
    }
}
const mapStateToProps = (currentState) => {
    return {};
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }

    };
} 
export default connect(mapStateToProps, mapActionsToProps)(ProductOperation);