import React, { Component } from 'react';

import H2Title from './../../Generic/Element/Heading';
import * as service from '../../../Service/service';
import * as jwt_decode from 'jwt-decode';
import keys from './../../../Config/config.json';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import AccountHeader from '../../Generic/Header/AccountHeader';

class AddProductComponent extends Component {
    state = { 

        choice:'',
        subCategoryList:[],
        productName:'',
        productSlug:'',
        category:'',
        subCategory:'',
        featuredImage:'',
        description:'',
        imageList:[],
        isDisabled:false,
        inv16:'',
        inv19:'',
        inv112:'',
        inv26:'',
        inv29:'',
        inv212:'',
        inv36:'',
        inv39:'',
        inv312:'',
        featureList:[1,2,3],
        title1:'',
        feature1:'',
        title2:'',
        feature2:'',
        title3:'',
        feature3:'',
        message:'',
        infoType:''
     }

    onCategorySelect=(e)=>{
        this.setState({
            ...this.state,
            subCategoryList:[],
            category: e.target.value,
        }, () => {
            if(this.state.category!='Category'){
            this.getSubCategory(this.state.category)
            }
            console.log(this.state);
        });
    }

    onSubCatSelect=(e)=>{
        this.setState({
            ...this.state,
            subCategory: e.target.value,
        }, () => {
            console.log(this.state);
        });
    }

    handleChange=(e)=> {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value
        });
        console.log(this.state);
      }
      
    componentDidMount(){

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Add Product', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
    }

    getSubCategory=(category)=>{

        var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }

            else{
                service.getSubCategoryByCategory(category).then((response)=>{

                    this.setState({
                        ...this.state,
                        subCategoryList:response.data
                    });
                    })
            }
    }

    submitProductData=()=>{

        this.setState({
            ...this.state,
            isDisabled:true,
        })

        let obj = {
            productName:this.state.productName,
            productSlug:this.state.productSlug,
            category:this.state.category,
            subCategory:this.state.subCategory,
            featuredImage:this.state.featuredImage,
            inventory16Month:this.state.inv16,
            inventory19Month:this.state.inv19,
            inventory112Month:this.state.inv112,
            inventory26Month:this.state.inv26,
            inventory29Month:this.state.inv29,
            inventory212Month:this.state.inv212,
            inventory36Month:this.state.inv36,
            inventory39Month:this.state.inv39,
            inventory312Month:this.state.inv312,
            imageList:this.state.imageList,
            description:this.state.description,
            title1:this.state.title1,
            title2:this.state.title2,
            title3:this.state.title3,
            feature1:this.state.feature1,
            feature2:this.state.feature2,
            feature3:this.state.feature3,
        }
        service.addNewProduct(obj).then((response)=>{
            this.setState({
                ...this.state,
                isDisabled:false,
                showInfo: true,
            });

            if(response.data){
                this.setState({
                    choice:'',
                    subCategoryList:[],
                    productName:'',
                    productSlug:'',
                    category:'',
                    subCategory:'',
                    featuredImage:'',
                    description:'',
                    imageList:[],
                    inv16:'',
                    inv19:'',
                    inv112:'',
                    inv26:'',
                    inv29:'',
                    inv212:'',
                    inv36:'',
                    inv39:'',
                    inv312:'',
                    featureList:[1,2,3],
                    title1:'',
                    feature1:'',
                    title2:'',
                    feature2:'',
                    title3:'',
                    feature3:'',

                    message : 'Product Added Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }

        })
    }

    render() { 
        return ( 
            <React.Fragment>
                <AccountHeader/>
                <BreadCrumbs/>
                <div className='container mt-3 mb-3'>
                           
                           <div className="row mt-5" style={{ height: '100%' }}>
                               <div className="col-4 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                            </div>
       
                            <div className="col-8 col-md-6 p-5 ml-3 mb-auto mt-auto position-relative sidebar element-border" style={{ maxHeight: '500px' }}>
                                        <H2Title value="Add Product" /><br/>
                                        {
                                            this.state.showInfo && (
                                                <div className={this.state.infoType} role="alert">
                                                    <span>{this.state.message}</span>
                                                </div>
                                            )
                                    }
                                    <div className='container ' >
                                     
                                        <div>
                                        <form className="mt-2">
                                            <div >
                                                <input type="text" required onChange={this.handleChange} name="productName" placeholder="Product Name" className="mr-5 col-5" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.productName}/>&nbsp;
                                                <input type="text" required onChange={this.handleChange} name="productSlug" placeholder="Slug Name" className="mb-4 col-5"  style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.productSlug}/><br/>
                                                <select value={this.state.category} required onChange={this.onCategorySelect}  className="text-secondary mb-3 mr-5 col-5" style={{border:'none',borderBottom:'1px solid #C0C0C0'}}>
                                                    <option>Category</option>
                                                    <option>Furniture</option>
                                                    <option>Appliances</option>
                                                    <option>Package</option>
                                                    <option>Premium</option>
                                                    <option>Medical Equipment</option>

                                                </select>
                                                
                                                <select value={this.state.subCategory} required onChange={this.onSubCatSelect} style={{border:'none',borderBottom:'1px solid #C0C0C0'}}  className="mb-4 text-secondary col-5">
                                                    <option>Sub-Category</option>
                                                    {this.state.subCategoryList.map((subCat,iKey) => (
                                                        <option key={iKey} value={subCat.name}>{subCat.name}</option>
                                                    ))}

                                                </select>
                                                <br/>
                                                <input type="text"  onChange={this.handleChange} name="featuredImage" className="mr-5 mb-4 col-11" placeholder="Featured Image" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.featuredImage}/><br/>

                                                <b><span className="text-secondary">Inventory</span></b><br/>

                                                <table className="mt-2 mb-4">
                                                    <tbody>
                                                        <tr>
                                                            <td >1</td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv16" placeholder="6 Mon" size='15' className="ml-4 "  style={{border:'none',}} value={this.state.inv16}/><br/></td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv19" placeholder="9 Mon" size='15'  style={{border:'none',}} value={this.state.inv19}/><br/></td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv112" placeholder="12 Mon" size='15'  style={{border:'none',}} value={this.state.inv112}/><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td >2</td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv26" placeholder="6 Mon" size='15' className="ml-4"  style={{border:'none',}} value={this.state.inv26}/><br/></td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv29" placeholder="9 Mon" size='15'  style={{border:'none',}} value={this.state.inv29}/><br/></td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv212" placeholder="12 Mon"  size='15' style={{border:'none',}} value={this.state.inv212}/><br/></td>
                                                        </tr>
                                                        <tr>
                                                            <td >3</td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv36" placeholder="6 Mon" size='15' className="ml-4"  style={{border:'none',}} value={this.state.inv36}/><br/></td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv39" placeholder="9 Mon"  size='15' style={{border:'none'}} value={this.state.inv39}/><br/></td>
                                                            <td ><input type="text" required onChange={this.handleChange} name="inv312" placeholder="12 Mon"  size='15' style={{border:'none'}} value={this.state.inv312}/><br/></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <b><span className="text-secondary">Features</span></b><br/><br/>
                                                    <div className="row">
                                                        <textarea type="text"  onChange={this.handleChange} name="title1" className="mr-5 ml-3 mb-4 col-3" placeholder="Title 1" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.title1}/>
                                                        <textarea type="text"  onChange={this.handleChange} name="feature1" className="mr-5 mb-4 col-6" placeholder="Features 1" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.feature1}/>
                                                    </div>
                                                    <div className="row">
                                                        <textarea type="text"  onChange={this.handleChange} name="title2" className="mr-5 ml-3 mb-4 col-3" placeholder="Title 2" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.title2}/>
                                                        <textarea type="text"  onChange={this.handleChange} name="feature2" className="mr-5 mb-4 col-6" placeholder="Features 2" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.feature2}/>
                                                    </div>
                                                    <div className="row">
                                                        <textarea type="text"  onChange={this.handleChange} name="title3" className="mr-5 ml-3 mb-4 col-3" placeholder="Title 3" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.title3}/>
                                                        <textarea type="text"  onChange={this.handleChange} name="feature3" className="mr-5 mb-4 col-6" placeholder="Features 3" style={{border:'none',borderBottom:'1px solid #C0C0C0'}} value={this.state.feature3}/>
                                                    </div>
                                                <textarea value={this.state.imageList} onChange={this.handleChange} name="imageList" className="form-control mt-4" id="imageList" rows="3" placeholder="Enter Images Link"></textarea>
                                                <textarea value={this.state.description} onChange={this.handleChange} name="description" className="form-control mt-4" id="description" rows="3" placeholder="Enter Description"></textarea>
                                            </div>
                                            
                                            
                                        </form>
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" onClick={()=>this.submitProductData()} disabled={this.state.isDisabled}>
                                                {
                                                    this.state.isDisabled?<div><span className="ml-2">Processing &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Submit"
                                                }
                                            </button>
                                        
                                        
                                        </div>
                                    
                                    </div>

                            </div>
                           </div>
                           
                               
                           </div>
                           
            </React.Fragment>
         );
    }
}
const mapStateToProps = (currentState) => {
    return {};
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }

    };
} 
export default connect(mapStateToProps, mapActionsToProps)(AddProductComponent);