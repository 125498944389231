import React, { Component, Fragment } from 'react';
import H2Title from "./../../Generic/Element/Heading";
import { MDBDataTableV5 } from "mdbreact";
import * as service from "./../../../Service/service";
import { connect } from "react-redux";
import keys from "./../../../Config/config.json";
import Pagination from "../../Generic/Element/Pagination";
import AccountHeader from "../../Generic/Header/AccountHeader";
import BreadCrumbs from "../../Generic/BreadCrumbs/BreadCrumbs";
import * as jwt_decode from "jwt-decode";

class IssueList extends Component {

    state = { 
            issueList: [],
            isLoaded: false,
            orderNumber: "",
            pageSize: 20,
            currentPage: 1,
            totalCount: 0,
            isPageLoaded: false,
            paginateLoad: false,
            sortBy: "All",
            search: "",
            searchFun: false,
            columns: [
            {
                label: "Issue Id",
                field: "issueId",
                width: 100,
            },
            {
                label: "Description",
                field: "description",
                sort: "disabled",
                width: 100,
            },
            {
                label: "Issue Type",
                field: "issueType",
                width: 100,
            },
            {
                label: "Reason",
                field: "reason",
                width: 100,
            },
            {
                label: "Username",
                field: "username",
                width: 100,
            },
            {
                label: "Issue Date",
                field: "createdAt",
                width: 100,
            }
            ],
            rows: [],
     }

     onSortBy = (event) => {
        this.setState(
          {
            ...this.state,
            sortBy: event.target.value,
            rows: [],
            issueList: [],
            isLoaded: false,
            currentPage: 1,
          },
          () => {
            this.listAllIssues();
            console.log(this.state);
          }
        );
      };
    
      onSearchBy = (event) => {
        this.setState(
          {
            ...this.state,
            searchBy: event.target.value,
          },
          () => {
            console.log(this.state);
          }
        );
      };
    
      onSearchEnter = (event) => {
        this.setState(
          {
            ...this.state,
            search: event.target.value,
            currentPage: 1,
          },
          () => {
            console.log(this.state);
          }
        );
      };

      listAllIssues = () => {
        var decoded = jwt_decode(localStorage.keycloak);
        if (JSON.parse(decoded.preferred_username != keys.admin_token)) {
          alert("Unauthorized access");
          localStorage.removeItem("keycloak");
          window.location.href = window.location.origin + "?url=" + window.location;
        } else {
          service.allIssues(
              this.state.sortBy,
              this.state.search,
              this.state.currentPage,
              this.state.pageSize
            )
            .then(
              (response) => {
                if (response.data.length == 0) {
                  this.setState({ isLoaded: false, rows: [] });
                }
    
                if (response.data.length != 0) {
                  this.setState({
                    ...this.state,
                    issueList: response.data,
                    isLoaded: true,
                    paginateLoad: false,
                    searchFun: false,
                    totalcount: response.headers["x-totalcount"],
                  });
    
                  this.state.issueList.map((issue) => {
                    let row = {
                      issueId: issue.issueId,
                      description: issue.description,
                      createdAt: issue.createdAt.slice(0, 10),
                      issueType: issue.issueType,
                      username: issue.username,
                      reason: issue.reason
                    };
                    this.state.rows.push(row);
                  });
    
                  this.getTableData();
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      };
      handlePageChange = (page) => {
        this.setState({
          ...this.state,
          issueList: [],
          rows: [],
          paginateLoad: true,
          currentPage: page,
          isPageLoaded: true,
          isLoaded: false,
        });
      };
    
      getTableData() {
        this.setState({
          ...this.state,
          datatable: {
            columns: this.state.columns,
            rows: this.state.rows,
          },
        });
      }

      componentDidMount() {
        this.props.updateBreadcumbs({
          navigation: [
            { name: "Home", link: "/" },
            { name: "Issues", link: "#" },
          ],
          showSidebar: false,
          sidebar: [],
        });
    
        if (localStorage.hasOwnProperty("keycloak") == false) {
          alert("Login to view");
          window.location.href = window.location.origin + "?url=" + window.location;
        } else if (localStorage.hasOwnProperty("keycloak") == true) {
          this.listAllIssues();
        }
      }
      componentDidUpdate() {
    
        if (this.state.searchFun) {
          this.listAllIssues();
        }
    
        if (this.state.isPageLoaded == true) {
          this.listAllIssues();
          this.setState({ isPageLoaded: false });
        }
      }
    
      _handleKeyDown = (e) => {
        if (e.key === "Enter") {
          this.setState({ rows: [], issueList: [], isLoaded: false });
          this.listAllIssues();
        }
      };
    
      searchByFunc = () => {
        if (
          this.state.search != "" 
        ) {
          this.setState({
            search: "",
            rows: [],
            currentPage: 1,
            issueList: [],
            isLoaded: false,
            searchFun: true,
          });
        }
      };

      
    render() { 
        return ( 
          <React.Fragment>
          <AccountHeader />
          <BreadCrumbs />
          <main role="main" className="container-fluid mt-3">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12">
                <H2Title value="Issues" />
                <div className="row element-border mt-3 mb-3 p-3" style={{ minHeight: "250px" }}>
                  {!this.state.isLoaded && (
                    <div className="col-12 text-center mt-5">
                      <div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <span className="pl-3 text-black-50 d-block">Checking backend...</span>
                    </div>
                  )}
        
                  {this.state.isLoaded && this.state.rows.length !== 0 && (
                    <div className="col-12 mt-2 mb-2">
                      <div className="row align-items-center">
                        <div className="col-md-4 col-12 mb-2">
                          <span className="font-weight-bold">Search By:</span>
                          <select
                            value={this.state.searchBy}
                            onChange={this.onSearchBy}
                            className="form-control"
                          >
                            <option>All</option>
                            <option>Field</option>
                          </select>
                        </div>
        
                        {this.state.searchBy === "All" && this.searchByFunc()}
        
                        {this.state.searchBy === "Field" && (
                          <div className="col-md-4 col-sm-12 d-flex">
                            <input
                              type="text"
                              value={this.state.search}
                              onChange={this.onSearchEnter}
                              onKeyDown={this._handleKeyDown}
                              className="form-control"
                              placeholder="Username/IssueId"
                            />
                          </div>
                        )}
                      </div>
        
                      <div className="table-responsive mt-3">
                        <MDBDataTableV5
                          searching={false}
                          paging={false}
                          info={false}
                          barReverse
                          striped
                          bordered
                          hover
                          noBottomColumns
                          responsive
                          data={this.state.datatable}
                        />
                      </div>
        
                      <div className="d-flex justify-content-center mt-3">
                        <Pagination
                          itemsCount={parseInt(this.state.totalcount)}
                          pageSize={this.state.pageSize}
                          onPageChange={this.handlePageChange}
                          currentPage={this.state.currentPage}
                          pageLoad={this.state.paginateLoad}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
        
         );
    }
}
const mapStateToProps = (currentState) => {
  };
  
  const mapActionsToProps = (dispatch) => {
    return {
      updateBreadcumbs: (data) => {
        dispatch({ type: "breadcrumbs.update", data: data });
      },
    };
  };
  
  export default connect(mapStateToProps, mapActionsToProps)(IssueList) ;