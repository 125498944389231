
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MdBasket from 'react-ionicons/lib/MdBasket';
import "bootstrap-css-only/css/bootstrap.min.css";
import * as service from '../../../Service/service'; 
import { MDBDataTableV5 } from 'mdbreact';
import OrderModal from '../../Generic/Modal/OrderModal';
import Pagination from '../../Generic/Element/Pagination';
import BreadCrumbs from '../../Generic/BreadCrumbs/BreadCrumbs';
import AccountHeader from '../../Generic/Header/AccountHeader';
import * as jwt_decode from 'jwt-decode';
import keys from './../../../Config/config.json';
class OrderComponent extends Component {

    state = {
        orders: [],
        click:'',
        isLoaded: false,  
        isModal:false,
        pageSize:20,
        currentPage :1 ,
        totalCount: 0,
        isPageLoaded: false,
        paginateLoad:false,
        search:'',
        startDate:'',
        endDate:'',
        searchFun:'',
        columns: [
                {
                  label: 'Order Number',
                  field: 'orderNumber',
                  sort: 'disabled',
                  width: 150,
                  attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Order Number',
                    
                  },
                },
                {
                  label: 'Order Date',
                  field: 'orderDate',
                  width: 270,
                },
                {
                  label: 'Username',
                  field: 'username',
                  width: 200,
                },
                {
                    label: 'Delivered On',
                    field: 'deliveryDate',
                    width: 100,
                },
                // {
                //   label: 'Expiry Date',
                //   field: 'expiryDate',
                //   width: 100,
                // },
                {
                    label: 'Returned On',
                    field: 'expiryDate',
                    width: 100,
                },
                {
                   label: 'Status',
                   field: 'status',
                   sort: 'disabled',
                   width: 100,
                },
                {
                    label: 'More Details',
                    field: 'more',
                    sort: 'disabled',
                    width: 100,
                 },          
              ],
            rows: [],
          
        }

        onSearchBy =(event) =>{
            this.setState({
                ...this.state,
                searchBy: event.target.value,
                
            }, () => {
                console.log(this.state);
            });
        }

    onSearchEnter = (event) => {
        this.setState({
            ...this.state,
            startDate:'',
            endDate:'',
            search: event.target.value,
            currentPage:1
        }, () => {
            console.log(this.state);
        });
    }

    onStartDate=(e) =>{
        this.setState({
            ...this.state,
            search:'',
            startDate: e.target.value
        }, () => {
            console.log(this.state);
        });
    }
    onEndDate=(e)=>{
        this.setState({
            ...this.state,
            username:'',
            endDate: e.target.value
        }, () => {
            
            console.log(this.state);
        });
    }
    
    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    viewMore = (orderNumber,username,paymentId,orderStatus)=>{
        this.props.showOrderModal({
            orderNumber: orderNumber,
            username: username,
            paymentId:paymentId,
            orderStatus: orderStatus,
        })
    }


    getAllOrders=()=>{

        var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }

            else{
                service.listOrders('ALL',this.state.search,this.state.startDate,this.state.endDate,this.state.currentPage,this.state.pageSize).then((response)=>{
            
                    if(response.data.length==0){
                        this.setState({isLoaded:true,rows:[]})
                    }

                if(response.data.length!=0){
                    this.setState({
                        ...this.state,
                        orders: response.data,
                        isLoaded: true,  
                        paginateLoad: false,
                        searchFun:false,
                        totalcount: response.headers['x-totalcount']
                    });

                    this.state.orders.map((order)=>{
                        let row = {
                            orderNumber:order.orderNumber,
                            orderDate: order.orderDate!=null?order.orderDate.slice(0,10):'',
                            username:order.username,
                            expiryDate: order.expirayDate!=null?order.expirayDate.slice(0,10):'--- NA ---',
                            deliveryDate: order.deliveryDate!=null?order.deliveryDate.slice(0,10):'--- NA ---',
                            status:order.orderStatus,
                            more : <a className='text-primary' style={{cursor:'pointer'}}  onClick={()=>this.viewMore(order.orderNumber,order.username,order.paymentId,order.orderStatus)}>View More</a>
                        }
                        console.log(row);
                        this.state.rows.push(row);
                    })
            
                    this.getTableData();
                }
            }, (error) => {
                console.log(error);
            });
        }
    }

    handlePageChange=page=>{
        this.setState({
            ...this.state,
            orders:[],
            rows:[],
            paginateLoad:true,
            currentPage:page,
            isPageLoaded:true,
            isLoaded:false,
        });

      }

      componentDidUpdate(){

       if(this.state.isPageLoaded==true){
            this.getAllOrders();
            this.setState({isPageLoaded:false});
        }

        if(this.state.searchFun){
            this.getAllOrders()
        }
      }
    
    componentDidMount(){
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Orders', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true ){
            this.getAllOrders();
        }
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({rows:[],orders:[],isLoaded:false})
            this.getAllOrders();
        }
        
      }

      findByDate=(click)=>{

        if(click=='click'){
            this.setState({
                ...this.state,
                click:'',
                rows: [],
                currentPage:1,
                orders:[],
                isLoaded:false
            });
            this.getAllOrders(); 
        }   
    }

    searchByFunc(){

        if(this.state.search!='' || (this.state.startDate!='' && this.state.endDate!='') ){
            this.setState({
                rows: [],
                currentPage:1,
                orders:[],
                isLoaded:false,
                startDate:'',
                endDate:'',
                search:'',
                searchFun:true,
            })
        }
    }


    render() {
        
        return (
            <React.Fragment>
            <AccountHeader />
            <BreadCrumbs />
    
            <main role="main" className="container mt-3">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <MdBasket fontSize="32px" color="#e8554e" />
                    <h2 className="voo-underline heading ml-2">Orders</h2>
                  </div>
    
                  {/* Loading Spinner */}
                  {!this.state.isLoaded && (
                    <div className="d-flex justify-content-center mt-5">
                      <div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <span className="pl-3 text-black-50">Checking backend . .</span>
                    </div>
                  )}
    
                  {/* No Orders Found */}
                  {this.state.isLoaded && this.state.orders.length === 0 && (
                    <div className="text-center mt-5">
                      <img height="150px" src="./svg/order.svg" alt="No Orders" />
                      <h5 className="mt-3 mb-5">No orders available.</h5>
                    </div>
                  )}
    
                  {/* Orders Table */}
                  {this.state.isLoaded && this.state.orders.length > 0 && (
                    <div className="mt-3">
                      {/* Search By Dropdown */}
                      <div className="row align-items-center">
                        <span className="font-weight-bold col-auto">Search By:</span>
                        <div className="col-6 col-md-3">
                          <select value={this.state.searchBy} onChange={this.onSearchBy} className="custom-select">
                            <option>All</option>
                            <option>Field</option>
                            <option>Order Date</option>
                          </select>
                        </div>
    
                        {/* Search Input */}
                        {this.state.searchBy === "Field" && (
                          <div className="col-12 col-md-4 mt-2 mt-md-0">
                            <input
                              type="text"
                              value={this.state.search}
                              onChange={this.onSearchEnter}
                              onKeyDown={this._handleKeyDown}
                              className="form-control"
                              placeholder="Username/OrderNumber"
                            />
                          </div>
                        )}
    
                        {/* Date Filters */}
                        {this.state.searchBy === "Order Date" && (
                          <div className="col-12 col-md-6 mt-2 mt-md-0">
                            <b>Start:</b>{" "}
                            <input
                              type="date"
                              value={this.state.startDate}
                              onChange={this.onStartDate}
                              className="form-control d-inline w-auto"
                            />
                            &nbsp;
                            <b>End:</b>{" "}
                            <input
                              type="date"
                              value={this.state.endDate}
                              onChange={this.onEndDate}
                              className="form-control d-inline w-auto"
                            />
                            &nbsp;
                            <button className="btn btn-primary btn-sm" onClick={() => this.findByDate("click")}>
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
    
                      {/* Orders Table */}
                      <div className="table-responsive mt-3">
                        <MDBDataTableV5 paging={false} info={false} searching={false} striped bordered hover data={this.state.datatable} />
                      </div>
    
                      {/* Pagination */}
                      <div className="mt-3">
                        <Pagination
                          itemsCount={parseInt(this.state.totalcount)}
                          pageSize={this.state.pageSize}
                          onPageChange={this.handlePageChange}
                          currentPage={this.state.currentPage}
                          pageLoad={this.state.paginateLoad}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
    
              <OrderModal />
            </main>
          </React.Fragment>
        )

    }
}

const mapStateToProps = (currentState) => {
    return {
        orderDetails: currentState.orderDetails,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showOrderModal: (data) => {
            dispatch({ type: 'order.modal.open', data : data });
        },

        
    };
}

export default connect(mapStateToProps, mapActionsToProps)(OrderComponent);