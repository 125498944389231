import React, { Component } from 'react';
import H2Title from './../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import HeaderComponent from './../Generic/Header/HeaderComponent';
import * as service from './../../Service/service';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import OrderModal from '../Generic/Modal/OrderModal';

class AddressOrderComponent extends Component {
    state = { 
        orders: [],
        addressId:'',
        isLoaded: false,  
        isModal:false,
        columns: [
                {
                  label: 'Order Number',
                  field: 'orderNumber',
                  sort: 'disabled',
                  width: 150,
                  attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Order Number',
                    
                  },
                },
                {
                  label: 'Order Date',
                  field: 'orderDate',
                  width: 270,
                },
                {
                  label: 'Username',
                  field: 'username',
                  width: 200,
                },
                {
                  label: 'Expiry Date',
                  field: 'expiryDate',
                  width: 100,
                },
                {
                   label: 'Status',
                   field: 'status',
                   sort: 'disabled',
                   width: 100,
                },
                {
                    label: 'More Details',
                    field: 'more',
                    sort: 'disabled',
                    width: 100,
                 },          
              ],
            rows: [],
     }

     constructor(props) {
        super(props);

        this.state = ({
            ...this.state,
            addressId: props.match.params.addressId
        });

    }


     getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    viewMore = (orderNumber,username,paymentId)=>{
        this.props.showOrderModal({
            orderNumber: orderNumber,
            username: username,
            paymentId:paymentId,
        })
    }

    getAllOrders=()=>{

        service.getOrderByAddressId(this.state.addressId).then((response)=>{
            
                this.setState({
                    ...this.state,
                    orders: response.data,
                    isLoaded: true,  
                });
                
                var decoded = jwt_decode((localStorage.keycloak))
                if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                    alert("Unauthorized access");
                    localStorage.removeItem('keycloak')
                    window.location.href=window.location.origin+'?url='+window.location
                }

                else if(response.data.length!=0){

                this.state.orders.map((order)=>{
                    let row = {
                        orderNumber:order.orderNumber,
                        orderDate: order.orderDate!=null?order.orderDate.slice(0,10):'',
                        username:order.username,
                        expiryDate: order.expiryDate!=null?order.expiryDate.slice(0,10):'--- NA ---',
                        status:order.orderStatus,
                        more : <a className='text-primary' style={{cursor:'pointer'}}  onClick={()=>this.viewMore(order.orderNumber,order.username,order.paymentId)}>View More</a>
                    }
                    this.state.rows.push(row);
                })
            
            this.getTableData();
        }
        }, (error) => {
            console.log(error);
        });
    }
    
    componentDidMount(){
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Addresses', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
        if(localStorage.hasOwnProperty("keycloak")==false){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true){
            this.getAllOrders();
        }
    }

    render() { 
        return ( 
            <React.Fragment>
                <HeaderComponent/>
               <BreadCrumbs/>
            <div className='container mt-3 mb-3' >
                    <div className="col-12 mt-3 mb-3">
                    <H2Title value="Addresses" />

                             {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                

                    {   
                        this.state.isLoaded &&(
                        <div className='col-12 mt-2 mb-2' style={{border:'1px solid #C0C0C0',borderRadius:'5px'}}>
                        <div className="mt-3 mb-3">                   
                            <MDBDataTableV5 paging={false} info={false}  barReverse striped bordered hover noBottomColumns responsive  data={this.state.datatable} />
                        </div>
                        </div>
                        )

                    }   

                    </div>
                    
                                           
                </div>
                <OrderModal/>
            </React.Fragment>
         );
    }
}

const mapStateToProps = (currentState) => {
    return {
        orderDetails: currentState.orderDetails,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showOrderModal: (data) => {
            dispatch({ type: 'order.modal.open', data : data });
        },

        
    };
}

export default connect(mapStateToProps, mapActionsToProps)(AddressOrderComponent);