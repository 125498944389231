import React, { Component } from 'react';
import H2Title from './../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import HeaderComponent from './../Generic/Header/HeaderComponent';
import {CSVLink} from 'react-csv';
import * as service from './../../Service/service';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';

class AddressComponent extends Component {
    state = { 
        addressList: [],  
        isLoaded: false,
        username:'',
        columns: [
            
            {
              label: 'Address Id',
              field: 'addressId',
              width: 100,
            },
            {
              label: 'Address 1',
              field: 'address1',
              sort: 'disabled',
              width: 100,
            },
            {
              label: 'Address 2',
              field: 'address2',
              width: 100,
            },
            {
                label: 'Address Type',
                field: 'addressType',
                width: 100,
             }  
             ,
            {
                label: 'City',
                field: 'city',
                width: 100,
             },
             {
                 label: 'Pincode',
                 field: 'pincode',
                 width: 100,
              },
              {
                label: 'View Order',
                field: 'viewOrder',
                width: 100,
             }          
          ],
        rows: [],
     }
     constructor(props) {
        super(props);

        this.state = ({
            ...this.state,
            username: props.match.params.username
        });

    }

    listAllAddress = ()=>{

        service.listAddressesByUsername(this.state.username).then((response) => {

            this.setState({
                ...this.state,
                addressList: response.data,
                isLoaded: true,
            });

            var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
            

            else if(response.data!=null){
                this.state.addressList.map((address)=>{
                    let row = {
                        addressId: address.Id,
                        address1:address.address1==''?'----NA----':address.address1,
                        address2: address.address2==''?'----NA----':address.address2,
                        addressType:address.addressType,
                        city : address.city,
                        pincode : address.pincode,
                        viewOrder : <a className='text-primary' href={window.location.origin+'/order/'+address.Id}>View</a>
                    }
                    this.state.rows.push(row);
                })
            }
            this.getTableData();
        }, (error) => {
            console.log(error);
        });
    }

    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    

    componentDidMount(){

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Addresses', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
        if(localStorage.hasOwnProperty("keycloak")==false){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true){
            this.listAllAddress();
        }
    }

    render() { 
        return ( 
            <React.Fragment>
               <HeaderComponent/>
               <BreadCrumbs/>
            <div className='container mt-3 mb-3' >
                    <div className="col-12 mt-3 mb-3">
                    <H2Title value="Addresses" />
   
                                {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                

                    {   
                        this.state.isLoaded &&(
                        <div className='col-12 mt-2 mb-2' style={{border:'1px solid #C0C0C0',borderRadius:'5px'}}>
                        <div className="mt-3 mb-3">                   
                            <MDBDataTableV5 paging={false} info={false}  barReverse striped bordered hover noBottomColumns responsive  data={this.state.datatable} />
                        </div>
                        </div>
                        )

                    }   

                    </div>
                    
                                           
                </div>
            </React.Fragment>
         );
    }
}

const mapStateToProps = (currentState) => {
    return {};
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }

    };
}
 
export default connect(mapStateToProps, mapActionsToProps)(AddressComponent);