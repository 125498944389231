const initialState = {
    
    order : {
        current : {},
        all : []
    },
    breadcrumbs : {
        navigation : [],
        sidebar : [],
        showSidebar : false
    },
    userMenu : {
        show : false
    },
    userState : {
        loggedIn : false,
        showLogin : false,
        keycloak : {},
        userInfo : {}
    },
    orderDetails : {
        showModal : false,
        data : {}
    },

    invoiceDetails : {
        showModal : false,
        data : {}
    },
    basicModal : {
        showModal : false,
        data : {}
    },
    isInvoiceChanged : {
        isUpdate : false,
    },
    isModalChanged : {
        isUpdate : false,
    },
    kyc : {
        showPanCardModal : false,
        showAddressProofModal : false,
        showJobIdModal : false,
        showBankStatementModal : false,
        showRentAgreementModal : false
    },

};

const reducer = (state = initialState, action) => {
    

    let tmpState = JSON.parse(JSON.stringify(state));

    switch(action.type){

        case "modal.changed.false" : {
            tmpState.isModalChanged.isUpdate = false;
            break;
        }
        case "modal.changed.true" : {
            tmpState.isModalChanged.isUpdate = true;
             break;
        }
        case "basic.modal.close" : {
            tmpState.basicModal.showModal = false;
            tmpState.basicModal.data = {};
            break;
        }

        case "basic.modal.open" : {
            tmpState.basicModal.showModal = true;
            tmpState.basicModal.data = action.data;
            break;
        }
        
        case "invoice.changed.false" : {
            tmpState.isInvoiceChanged.isUpdate = false;
            break;
        }
        case "invoice.changed.true" : {
            tmpState.isInvoiceChanged.isUpdate = true;
             break;
        }
        case "invoice.modal.close" : {
            tmpState.invoiceDetails.showModal = false;
            tmpState.invoiceDetails.data = {};
            break;
        }

        case "invoice.modal.open" : {
            tmpState.invoiceDetails.showModal = true;
            tmpState.invoiceDetails.data = action.data;
            break;
        }
        
        case "order.modal.close" : {
            tmpState.orderDetails.showModal = false;
            tmpState.orderDetails.data = {};
            break;
        }

        case "order.modal.open" : {
            tmpState.orderDetails.showModal = true;
            tmpState.orderDetails.data = action.data;
            break;
        }   
        

        case "login.window.hide" : {
            tmpState.userState.showLogin = false;
            break;
        }

        case 'update.order.current' : {
            tmpState.order.current = action.data;
            break;
        }

        case 'breadcrumbs.update' : {
            tmpState.breadcrumbs = action.data;
            break;
        }

        case 'breadcrumbs.navigation.update' : {
            tmpState.breadcrumbs.navigation = action.data;
            break;
        }

        case 'breadcrumbs.sidebar.update' : {
            tmpState.breadcrumbs.sidebar = action.data;
            break;
        }

        case "user.auth.login" : {
            tmpState.userState.keycloak = action.data;
            tmpState.userState.loggedIn = true;
            break;
        }

        case "user.auth.logout" : {
            tmpState.userState.loggedIn = false;
            break;
        }

        case "login.update.userInfo" : {
            tmpState.userState.userInfo = action.data;
            break;
        }

        case "login.window.show" : {
            tmpState.userState.showLogin = true;
            break;
        }

        case "login.window.hide" : {
            tmpState.userState.showLogin = false;
            break;
        }

        case 'user.menu.show' : {
            tmpState.userMenu.show = true;
            break;
        }

        case 'user.menu.hide' : {
            tmpState.userMenu.show = false;
            break;
        }
        case 'kyc.pancard.show' : {
            tmpState.kyc.showPanCardModal = true;
            break;
        }

        case 'kyc.pancard.hide' : {
            tmpState.kyc.showPanCardModal = false;
            break;
        }

        case 'kyc.addressProof.show' : {
            tmpState.kyc.showAddressProofModal = true;
            break;
        }

        case 'kyc.addressProof.hide' : {
            tmpState.kyc.showAddressProofModal = false;
            break;
        }
        case 'kyc.jobId.show' : {
            tmpState.kyc.showJobIdModal = true;
            break;
        }

        case 'kyc.jobId.hide' : {
            tmpState.kyc.showJobIdModal = false;
            break;
        }

        case 'kyc.bankStatement.show' : {
            tmpState.kyc.showBankStatementModal = true;
            break;
        }

        case 'kyc.bankStatement.hide' : {
            tmpState.kyc.showBankStatementModal = false;
            break;
        }

        case 'kyc.rentAgreement.show' : {
            tmpState.kyc.showRentAgreementModal = true;
            break;
        }

        case 'kyc.rentAgreement.hide' : {
            tmpState.kyc.showRentAgreementModal = false;
            break;
        }

       
        default : return tmpState;
    }

    return {
        ...tmpState
    }

};

export default reducer;