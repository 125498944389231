import React, { Component } from 'react';

import H2Title from '../Generic/Element/Heading';
import * as service from '../../Service/service';
import HeaderComponent from '../Generic/Header/HeaderComponent';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';

class MarkReturnedComponent extends Component {
    state = { 
        orderNumber:'',
        orderStatus:'',
        comments:'',
        isReturn:false,
        isReturning:false,
        showInfo:false,
        message:'',
        infoType:'',
        choice: 'Select the choice',
        isLoaded: false,
        isDelivering:false,
        isDeliver:false,
        isCancelling:false,
        isCancelled:false,
        deliveryDate:'',
        showInvoice:false,
        addresses:[],
        addressId:'',
        username:'',
        slugName:'',
        slugQuantity:'',
        productKeepList:[],
        selectedProduct:'',
     }

     constructor(props) {
        super(props);

        this.state = ({
            ...this.state,
            orderNumber: props.match.params.orderNumber,
            username: props.match.params.username,
            orderStatus : props.match.params.orderStatus

        });

    }

    onCommentsEnter=(e)=>{
        this.setState({
            ...this.state,
            comments: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onChoiceSelect=(e)=>{
        this.setState({
            ...this.state,
            choice: e.target.value,
            showInfo:false,
        }, () => {
            console.log(this.state);
        });
    }

    handleChange=(e)=>{
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onAddressSelect=(e)=>{
        this.setState({
            ...this.state,
            addressId: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    getAddressId(){

        service.listAddressesByUsername(this.state.username).then((response)=>{
            this.setState({
                addresses : response.data
            }, () => {
                console.log(this.state);
            })
    })
}

    returnOrder=()=>{

        this.setState({
            ...this.state,
            isReturning:true,
        })

        let obj = {
            orderNumber:this.state.orderNumber,
            comments:this.state.comments,
        }
        service.submitReturnOrderDetails(obj).then((response)=>{
            this.setState({
                ...this.state,
                isReturn: true,
                showInfo: true,
            });

            if(response.data){
                this.setState({
                    message : 'Returned Marked Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }

        })
    }

    markDelivered=()=>{
        this.setState({
            ...this.state,
            isDelivering:true,
        })

        let obj = {
            orderNumber: this.state.orderNumber,
            deliveryDate: this.state.deliveryDate  
        };
        service.markOrderDelivered(obj).then((response)=>{

            this.setState({
                ...this.state,
                isDeliver:true,
                showInfo: true
            });
            if(response.data){
                this.setState({
                    message : 'Mark Delivered Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
    }

    markCancelled=()=>{
        this.setState({
            ...this.state,
            isCancelling:true,
        })
        service.markOrderCancelled(this.state.orderNumber).then((response)=>{

            this.setState({
                ...this.state,
                isCancelled:true,
                showInfo: true
            });
            if(response.data){
                this.setState({
                    message : 'Mark Cancelled Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
    }

    changeAddress=()=>{
        this.setState({
            ...this.state,
            isUpdate:true,
        })

        let obj = {
            orderNumber : this.state.orderNumber,
            addressId: this.state.addressId,  
        };
        service.changeOrderAddressId(obj).then((response)=>{

            this.setState({
                ...this.state,
                isUp:true,
                showInfo: true,
                isUpdate:false
            });
            if(response.data){
                this.setState({
                    message : 'Address updated Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
    }

    isOrderUnpaid=()=>{

        service.isOrderUnpaid(this.state.orderNumber).then((response)=>{

            this.setState({
                ...this.state,
                isReturning:response.data,       
            });
            var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
            
            if(this.state.isReturning){
                this.setState({
                    showInvoice:true,
                    message : 'Invoices for this order are unpaid',
                    infoType : 'alert alert-dismissible fade show alert-danger mt-2'
                })
            }
        })
    }

    

    componentDidMount(){

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Order Update', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true){
            this.isOrderUnpaid();
            this.getAddressId();
            this.getProduct();
        }
    }

    getProduct=()=>{
        service.listOrderProducts(this.state.orderNumber).then((response)=>{
            this.setState({
                productList : response.data
            }, () => {
                console.log(this.state);
            })
    })
    }

    componentDidUpdate(){
        if(this.state.isReturn || this.state.isDeliver || this.state.isUp || this.state.isCancelled){
            this.setState({comments:'',isReturn:false,deliveryDate:'',isDeliver:false,isDelivering:false,isReturning:false,isUp:false,isCancelling:false,isCancelled:false})
        }
    }

    addToProductList=()=>{
        var list=this.state.productKeepList;
        list.push({slug:this.state.slugName , quantity:this.state.slugQuantity})
        this.setState({productKeepList:list, slugName:'' ,slugQuantity:''})
        console.log(list)
    }

    removeProduct=(product)=>{
        var list=this.state.productKeepList;
        list.pop({slug:product.slug , quantity:product.quantity})
        this.setState({productKeepList:list})
        console.log(list)
    }

    adjustOrder=()=>{
        this.setState({
            ...this.state,
            isUpdate:true,
        })
        let obj = {
            productList : this.state.productKeepList  ,
            orderNumber : this.state.orderNumber
        };
        service.adjustOrderProducts(obj).then((response)=>{

            this.setState({
                ...this.state,
                isUp:true,
                showInfo: true,
                isUpdate:false
            });
            if(response.data){
                this.setState({
                    message : 'Order Adjusted Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
    }
    render() { 
        return (

        <React.Fragment>
            <HeaderComponent/>
            <BreadCrumbs/>
            <div className='container mt-3 mb-3'>
                           
                           <div className="row mt-5" style={{ height: '100%' }}>
                               <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                            </div>
       
                            <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>
                                        <H2Title value="Order Operations" />
                                        <br/>
                                        <b>Order Number  : </b> <span>{this.state.orderNumber}</span><br/>
                                        <label htmlFor="choice" className="font-weight-bold mt-2">Select the operation</label>
                                        <select value={this.state.choice} onChange={this.onChoiceSelect}  className="browser-default custom-select mb-2" id="choice" placeholder="Select the choice">
                                            <option>Select the choice</option>
                                            <option>Mark Delivered</option>
                                            <option>Mark Returned</option>
                                            <option>Mark Cancelled</option>
                                            <option>Change Address</option>
                                            {this.state.orderStatus !== 'ADJUSTED' && (<option>Order Adjust</option>)}
                                        </select>
                                        
                                    {
                                            this.state.showInfo && (
                                                <div className={this.state.infoType} role="alert">
                                                    <span>{this.state.message}</span>
                                                </div>
                                            )
                                    }
                                
                            { 
                                this.state.choice=='Mark Returned' && (
                                    <div >   
                                    
                                    {
                                            this.state.showInvoice && (
                                                <div className={this.state.infoType} role="alert">
                                                    <span>{this.state.message}</span>
                                                </div>
                                            )
                                    }
                                    {
                                        this.state.showInvoice && (
                                            <span>Click here to view Invoices : <a className='text-primary' style={{cursor:'pointer'}} href={window.location.origin+"/view/invoices/"+this.state.orderNumber}>View Invoices</a></span>
                                        )
                                    }

                                    <form>

                                        <div>
                                            <textarea style={{border:'1px solid #D3D3D3',width: '100%'}} value={this.state.comments} onChange={this.onCommentsEnter} className="form-control mt-2" rows="3" placeholder="Enter Comments"></textarea>
                                        </div>
                                    </form>
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isReturning} onClick={()=> this.returnOrder()}>
                                            Mark Return
                                        </button>
                                    </div>
                                )
                            }

                            { 
                                this.state.choice=='Mark Delivered' && (
                                    <div >
                                     
                                        <form className="mt-2">
                                            
                                            <div className="mt-2">
                                                <label htmlFor="description" className="font-weight-bold">Select Delivery Date</label>
                                                <input type="date" onChange={this.handleChange} name='deliveryDate' className="form-control" value={this.state.deliveryDate}/>
                                            </div>
                                        </form>
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isDelivering} onClick={()=> this.markDelivered()}>
                                        Mark Delivered
                                        </button>
                                    </div>
                                )
                            }
                            { 
                                this.state.choice=='Mark Cancelled' && (
                                    <div >
                                     
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isCancelling} onClick={()=> this.markCancelled()}>
                                        Mark Cancelled
                                        </button>
                                    </div>
                                )
                            }

                            { 
                                this.state.choice=='Change Address' &&(
                                    <div>   
                                        
                                    <span className="mt-2">Click here to view Addresses : <a className='text-primary mb-2' style={{cursor:'pointer'}} href={window.location.origin+"/view/address/"+this.state.username}>View Addresses</a></span>
                                    <form>
                                            <label htmlFor="addressId" className="font-weight-bold">Select Address Id</label>                     
                                            <select value={this.state.addressId} onChange={this.onAddressSelect}  className="browser-default custom-select" id="addressId">
                                                <option>Select Address Id</option>
                                                {this.state.addresses.map((address,iKey) => (
                                                    <option key={iKey} value={address.Id}>{address.Id}</option>
                                                ))}
                                            </select>
                                    </form>
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isUpdate} onClick={()=> this.changeAddress()}>
                                            Update Address
                                        </button>
                                    </div>
                                )
                            }

{ 
                                this.state.choice=='Order Adjust' &&(
                                    <div>   
                                        
                                    <form className='row mt-2'>
                                            <select value={this.state.slugName} name='slugName' onChange={this.handleChange}  className="browser-default custom-select col-4 ml-3">
                                                <option>Product to keep</option>
                                                {this.state.productList.map((product,iKey) => (
                                                    <option key={iKey} value={product.productSlug}>{product.productSlug}</option>
                                                    
                                                ))}
                                            </select>
                                            <input type='number' min='1' max='' onChange={this.handleChange} name='slugQuantity' className="form-control col-2 mr-2 ml-3" placeholder='quantity' value={this.state.slugQuantity}/>
                                            <button  type="button" className="btn btn-success col-2 btn-sm" disabled={this.state.isUpdate} onClick={()=> this.addToProductList()}>
                                            Add
                                        </button>

                                    </form>
                                    { (this.state.productKeepList.length!==0) && (
                                    <div className='mt-2'>
                                    <b >
                                        Selected Product
                                    </b>
                                    
                                    <div className="col-12">
                                        {this.state.productKeepList.map((product,iKey) => (
                                                    <tr>
                                                    <td>{product.slug}</td>
                                                    <td>:</td>
                                                    <td>{product.quantity}</td>
                                                    <td><button className="btn btn-danger btn-sm" onClick={()=>this.removeProduct(product)}>Remove</button></td>
                                                    </tr>
                                                ))}
                                    </div>
                                    </div>
                                    )}
                                        
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isUpdate} onClick={()=> this.adjustOrder()}>
                                            Adjust Order
                                        </button>
                                    </div>
                                )
                            }
                            </div>
                           </div>
                           
                               
                           </div>
                           

        </React.Fragment>  
        
        );
    }
}
const mapStateToProps = (currentState) => {
    return {};
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }

    };
} 
export default connect(mapStateToProps, mapActionsToProps)(MarkReturnedComponent);