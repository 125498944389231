import React, { Component } from 'react';
import H2Title from '../Generic/Element/Heading';
import * as service from '../../Service/service';
import HeaderComponent from '../Generic/Header/HeaderComponent';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';

class AddNewInvoiceComponent extends Component {
    state = { 
        orderNumber:'',
        generatedDate:'',
        isDisabled:false,
        showInfo:false,
        message:'',
        infoType:'',
        choice: 'Select Option',
        operation: '',
        interestDate:'',
     }

    onOrderNumberEnter=(e)=>{
        this.setState({
            ...this.state,
            orderNumber: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onChoiceSelect=(e)=>{
        this.setState({
            ...this.state,
            choice: e.target.value,
            showInfo:false,
            isDisabled:'',
            orderNumber:'',
            generatedDate:'',
            message:''
        }, () => {
            console.log(this.state);
        });
    }

    onSelectOperation=(e)=>{
        this.setState({
            ...this.state,
            operation: e.target.value,
            showInfo:false,
            isDisabled:'',
            orderNumber:'',
            generatedDate:'',
            message:''
        }, () => {
            console.log(this.state);
        });
    }

    onGeneratedOn=(e)=>{
        this.setState({
            ...this.state,
            generatedDate: e.target.value
        }, () => {
            console.log(this.state);
        });
    }
    onInterestDate=(e)=>{
        this.setState({
            ...this.state,
            interestDate: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onAddressSelect=(e)=>{
        this.setState({
            ...this.state,
            addressId: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    getAddressId(){

        service.listAddressesByUsername(this.state.username).then((response)=>{
            this.setState({
                addresses : response.data
            }, () => {
                console.log(this.state);
            })
    })
}


    addNewInvoice=(choice,addingParam)=>{
        this.setState({
            ...this.state,
            isDisabled:true,
        })

        let obj = {
            addingParameter: addingParam,
            choice: choice  
        };
        service.addNewInvoices(obj).then((response)=>{

            this.setState({
                ...this.state,
                orderNumber:'',
                generatedDate:'',
                isDisabled:false,
                showInfo: true
            });
            if(response.data.length!=0){
                this.setState({
                    message : 'Invoice Created Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'
                })
            }
            if(!response.data.length==0){
                this.setState({
                    isDisabled:false,
                    orderNumber:'',
                    generatedDate:'',
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
    }

    sendNotification=()=>{
        this.setState({
            ...this.state,
            isDisabled:true,
        })
            
        service.sendNotification().then((response)=>{
            this.setState({
                ...this.state,
                isDisabled:false,
                showInfo: true,
            });
            if(response.data){
                this.setState({
                    operation:'',
                    message : 'Pending notifications has been sent successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'

                })
            }
            if(!response.data){
                this.setState({
                    isDisabled:false,
                    operation:'',
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
    }

    generateInterest=(interestDate,operation)=>{
        this.setState({
            ...this.state,
            isDisabled:true,
        })

        if(operation === "One Time Generate Interest" )
        {    
        service.generateInterest(interestDate).then((response)=>{
            this.setState({
                ...this.state,
                isDisabled:false,
                showInfo: true,
                interestDate:'',
            });
            if(response.data.length!=0){
                this.setState({
                    message : 'Interest Generated Successfully',
                    infoType : 'alert alert-dismissible fade show alert-success'

                })
            }
            if(!response.data.length==0){
                this.setState({
                    isDisabled:false,
                    operation:'',
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible fade show alert-danger'
                })
            }
        })
        }
        else if(operation === "Monthly Interest"){
            service.monthlyInterest(interestDate).then((response)=>{
                this.setState({
                    ...this.state,
                    isDisabled:false,
                    showInfo: true,
                    interestDate:'',
                });
                if(response.data.length!=0){
                    this.setState({
                        message : 'Interest Generated Successfully',
                        infoType : 'alert alert-dismissible fade show alert-success'
    
                    })
                }
                if(!response.data.length==0){
                    this.setState({
                        isDisabled:false,
                        operation:'',
                        message : 'Something went wrong',
                        infoType : 'alert alert-dismissible fade show alert-danger'
                    })
                }
            })
        }
    }
    

    componentDidMount(){

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Order Update', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
    }
    
    render() { 
        return (

        <React.Fragment>
            <HeaderComponent/>
            <BreadCrumbs/>
            <div className='container mt-3 mb-3'>
                           
                           <div className="row mt-5" style={{ height: '100%' }}>
                               <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                            </div>
                            
       
                            <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>
                                        <H2Title value="Invoice Operations" />
                                        <br/>

                                        

                                        <label htmlFor="choice" className="font-weight-bold mt-2">Operation</label>
                                        <select value={this.state.operation} onChange={this.onSelectOperation}  className="browser-default custom-select mb-2" id="choice">
                                            <option>Select Option</option>
                                            <option>One Time Generate Interest</option>
                                            <option>Monthly Interest</option>
                                            <option>Create Invoice</option>
                                            <option>Send Pending Notification</option>
                                        </select>

                                        {
                                            this.state.showInfo && (
                                                <div className={this.state.infoType} role="alert">
                                                    <span>{this.state.message}</span>
                                                </div>
                                            )
                                        }

                            { (this.state.operation==="One Time Generate Interest" || this.state.operation==="Monthly Interest") && (
                                <div>
                                    <form className="mt-2">
                                        <label htmlFor="description" className="font-weight-bold">Select Interest Date</label>
                                        <input type="date" onChange={this.onInterestDate} className="form-control" value={this.state.interestDate}/>
                                    </form>
                                    <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isDisabled} onClick={()=> this.generateInterest(this.state.interestDate,this.state.operation)}>
                                            Submit
                                    </button> 
                                </div>
                            )}

                            {
                                this.state.operation=="Send Pending Notification" && (
                                    <div>
                                    <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isDisabled} onClick={()=> this.sendNotification()}>
                                            Send Notification
                                    </button> 
                                </div>
                                )
                            }
                            { this.state.operation==="Create Invoice" && (
                                <div>
                                        <label htmlFor="choice" className="font-weight-bold mt-2">Add Invoice By</label>
                                        <select value={this.state.choice} onChange={this.onChoiceSelect}  className="browser-default custom-select mb-2" id="choice">
                                            <option>Select Option</option>
                                            <option>Order Number</option>
                                            <option>Generated On</option>
                                        </select>
                                        
                                  

                            { 
                                this.state.choice=='Generated On' && (
                                    <div >
                                     
                                        <form className="mt-2">
                                            
                                            <div className="mt-2">
                                                <label htmlFor="description" className="font-weight-bold">Select Generated Date</label>
                                                <input type="date" onChange={this.onGeneratedOn} className="form-control" value={this.state.generatedDate}/>
                                            </div>
                                        </form>
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isDisabled} onClick={()=> this.addNewInvoice(this.state.choice,this.state.generatedDate)}>
                                            Submit
                                        </button>
                                    </div>
                                )
                            }

                            { 
                                this.state.choice=='Order Number' && (
                                    <div >
                                     
                                        <form className="mt-2">
                                            
                                            <div className="mt-2">
                                                <label htmlFor="order" className="font-weight-bold">Order  Number</label>
                                                <input type="text" onChange={this.onOrderNumberEnter} id="order" className="form-control" placeholder="Order Number" value={this.state.orderNumber}/>
                                            </div>
                                        </form>
                                        <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" disabled={this.state.isDisabled} onClick={()=> this.addNewInvoice(this.state.choice,this.state.orderNumber)}>
                                        Submit
                                        </button>
                                    </div>
                                )
                            }   
                            </div>     
                            )}   
                            
                            </div>
                           </div>
                           
                               
                           </div>
                           

        </React.Fragment>  
        
        );
    }
}
const mapStateToProps = (currentState) => {
    return {};
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }

    };
} 
export default connect(mapStateToProps, mapActionsToProps)(AddNewInvoiceComponent);