import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import "@fortawesome/fontawesome-free/css/all.min.css";
import reducer from './Store/reducer';
import AccountWrapper from './Components/Account/AccountWrapper';

import ResetPassword from './Components/User/Login/Reset/ResetPassword';

import InvoiceComponent from './Components/Standalone/InvoiceComponent';
import OrderSummaryComponent from './Components/Standalone/OrderSummaryComponent';

import axios from 'axios';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import LoginPageComponent from './Components/User/GeneralLogin/LoginPage';
import PaymentComponent from './Components/Standalone/PaymentComponent';
import ForgotComponent from './Components/User/Login/Forgot/ForgotComponent';
import MarkReturnedComponent from './Components/Standalone/MarkReturnedComponent';
import InvoiceSummaryComponent from './Components/Standalone/InvoiceSummayComponent';
import IssueResponseComponent from './../src/Components/Standalone/IssueResponseComponent';
import AddressComponent from './Components/Standalone/AddressComponent';
import AddressOrderComponent from './Components/Standalone/AddressOrderComponent';
import OrderComponent from './Components/Account/Order/OrderComponent';
import UserComponent from './Components/Account/UserDetailComponent/UserComponent';
import ReportComponent from './Components/Account/Report/ReportComponent';
import AllPaymentComponent from './Components/Account/Payments/AllPaymentComponent';
import AllInvoiceComponent from './Components/Account/Invoice/AllInvoiceComponent';
import OrderExpiryComponent from './Components/Account/OrderExpiry/OrderExpiryComponent';
import AddProductComponent from './Components/Account/AddProduct/AddProductComponent';
import AddNewInvoiceComponent from './Components/Standalone/AddNewInvoiceComponent';
import ProductOperation from './Components/Account/ProductOperations/ProductOperations'
import IssueListComponent from './Components/Account/IssueList/IssueListComponent';
import KycComponent from './Components/Account/Kyc/KycComponent';

const store = createStore(reducer);

let keycloak = {};

window.addEventListener('login', function (e) {

    keycloak = JSON.parse(localStorage.keycloak);
    axios.defaults.headers.common['Authorization'] = keycloak.token_type + " " + keycloak.access_token;
    
}, false);

if (typeof localStorage.keycloak != "undefined") {
    keycloak = JSON.parse(localStorage.keycloak);
    axios.defaults.headers.common['Authorization'] = keycloak.token_type + " " + keycloak.access_token;
}

const routing = (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route exact path="/account" component={AccountWrapper} />
                <Route exact path="/upload/:username" component={KycComponent} />
                <Route exact path="/orders" component={OrderComponent} />
                <Route exact path="/invoices" component={AllInvoiceComponent} />
                <Route exact path="/issues" component={IssueListComponent} />
                <Route exact path="/newInvoice" component={AddNewInvoiceComponent} />
                <Route exact path="/users" component={UserComponent} />
                <Route exact path="/reports" component={ReportComponent} />
                <Route exact path="/addProduct" component={AddProductComponent} />
                <Route exact path="/productManagement" component={ProductOperation} />
                <Route exact path="/payments" component={AllPaymentComponent} />
                <Route exact path="/deliveredOrders" component={OrderExpiryComponent} />
                <Route exact path="/forget/password" component={ForgotComponent} />
                <Route exact path="/view/invoices/:orderNumber" component={InvoiceComponent} />
                <Route exact path="/view/order/:orderNumber" component={OrderSummaryComponent} />
                <Route exact path="/order/:addressId" component={AddressOrderComponent} />
                <Route exact path="/view/address/:username" component={AddressComponent} />
                <Route exact path="/mark/:orderNumber/:username/:orderStatus" component={MarkReturnedComponent} />
                <Route exact path="/get/issues/:issueAgainst" component={IssueResponseComponent} />
                <Route exact path="/invoice/summary/:invoiceNumber" component={InvoiceSummaryComponent} />
                <Route exact path="/view/payments/:paymentId" component={PaymentComponent} />
                <Route exact path="/password/reset/:username/:token" component={ResetPassword} />
                <Route exact path="/" component={localStorage.keycloak!=null?AccountWrapper:LoginPageComponent} />
               
            </Switch>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));
serviceWorker.unregister();
