import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import * as service from './../../../Service/service';
import { connect } from 'react-redux';
import UserModal from './../../Generic/Modal/UserModal';
import Pagination from '../../Generic/Element/Pagination';
import BreadCrumbs from '../../Generic/BreadCrumbs/BreadCrumbs';
import AccountHeader from '../../Generic/Header/AccountHeader';
import * as jwt_decode from 'jwt-decode';
import keys from './../../../Config/config.json';


class UserComponent extends Component {

    state = {
        userList: [],  
        isLoaded: false,
        username:'',
        pageSize:20,
        currentPage :1 ,
        totalCount: 0,
        isPageLoaded: false,
        paginateLoad:false,
        search:'',
        isback:false,
        columns: [
            
            {
              label: 'Name',
              field: 'name',
              width: 100,
            },
            {
              label: 'Email',
              field: 'email',
              sort: 'disabled',
              width: 100,
            },
            {
              label: 'Contact',
              field: 'contact',
              width: 100,
            },
            {
                label: 'Username',
                field: 'username',
                width: 100,
             }  
             ,
             {
                label: 'Register Date',
                field: 'createdAt',
                width: 100,
             } 
             ,
            {
                label: 'View More',
                field: 'more',
                width: 100,
             }        
          ],
        rows: [],
       
    }

    onSearchEnter = (event) => {
        this.setState({
            ...this.state,
            startDate:'',
            endDate:'',
            search: event.target.value,
            currentPage:1
        }, () => {
            console.log(this.state);
        });
    }

    listAllUsers = () => {

        var decoded = jwt_decode((localStorage.keycloak))
        if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
            alert("Unauthorized access");
            localStorage.removeItem('keycloak')
            window.location.href=window.location.origin+'?url='+window.location
        }

        else{
       
        service.listAllUsers(this.state.search,this.state.currentPage,this.state.pageSize).then((response) => {

            
            if(response.data.length==0){
                this.setState({isLoaded:true,rows:[]})
            }

            if(response.data.length!=0){
                this.setState({
                    ...this.state,
                    userList: response.data,
                    isLoaded: true,
                    paginateLoad: false,
                    isBack:false,
                    totalcount: response.headers['x-totalcount']
                });
                this.state.userList.map((user)=>{
                    let row = {
                        name:user.firstName+' '+user.lastName,
                        email:user.email,
                        contact : user.contact,
                        username:user.username,
                        createdAt:user.createdAt.slice(0, 10),
                        more : <a className='text-primary' style={{cursor:'pointer'}}  onClick={()=>this.viewMore(user.username)}>View More</a>
                    }
                    this.state.rows.push(row);
                })
            }
            this.getTableData();
        }, (error) => {
            console.log(error);
        });
    }
    }
    handlePageChange=page=>{
        this.setState({
            ...this.state,
            userList:[],
            rows:[],
            paginateLoad:true,
            currentPage:page,
            isPageLoaded:true,
            isLoaded:false,
        });

      }
    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    viewMore = (username)=>{
        this.props.showUserModal({
            username: username,
       })
    }
    componentDidMount() {
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'User Details', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true ){
        this.listAllUsers();
        }
        
    }
    componentDidUpdate(){
        if( this.props.isModalChanged.isUpdate==true){
            this.setState({
                ...this.state,
                rows: [],
            })
    
            this.listAllUsers(); 
            this.props.updateUserComponentFalse();
        }
        if(this.state.isBack ==true){
            this.listAllUsers();
        }

        if(this.state.isPageLoaded==true){
            this.listAllUsers();
            this.setState({isPageLoaded:false});
        }
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({rows:[],userList:[],isLoaded:false})
            this.listAllUsers();
        }
      }

    handleBack=()=>{
        this.setState({
            rows:[],
            userList:[],
            isBack:true,
            isLoaded:false,
            search:'',
            currentPage:1
        })
    }


    render() {
        return (
            <React.Fragment>
            
            
            <AccountHeader/>
            <BreadCrumbs/>
                <main role="main" className="container row  mt-3" style={{marginLeft:'auto',marginRight:'auto'}}>        
                <div className="col-12">
                        <H2Title value="User Details" />

                        <div className="row element-border mt-3 mb-3 mr-1 ml-1" style={{ minHeight: '250px' }}>


                            {
                                !this.state.isLoaded && (<div className="col-12 m-auto">
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                        </div>
                                    </div>
                                </div>)
                            }
                                
                    {
                        this.state.isLoaded && this.state.rows.length==0 && (
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <img height="150px" src="./svg/order.svg" />
                                                <h5 className="mt-3 mb-5">No user details are available.</h5>
                                            </div>
                                        </div>
                                    </div>
                        )
                    }
                                

                    {   
                        this.state.isLoaded && this.state.rows.length!=0 && (
                        <div className=' col-12 mt-2 mb-2' >
                        <div className='row mt-2'>

                            <input type='text' value={this.state.search} onChange={this.onSearchEnter} onKeyDown={this._handleKeyDown}  className="form-control m-2" placeholder="Search" />
                            <div className='col-8'></div>
                            {
                                this.state.search.length!=0 && (
                                <button className='float-right btn btn-primary col-1' onClick={()=>this.handleBack()}>Back</button>
                                )
                            }
                        </div>     
                        <div className="mt-3 mb-3">
                         
                        <MDBDataTableV5 paging={false} info={false} searching={false} barReverse striped bordered hover noBottomColumns responsive  data={this.state.datatable} />
                        </div>
                        <div className="ml-3">
                            <Pagination 
                                itemsCount={parseInt(this.state.totalcount)} 
                                pageSize={this.state.pageSize} 
                                onPageChange={this.handlePageChange}
                                currentPage={this.state.currentPage}
                                pageLoad={this.state.paginateLoad}
                            />
                        </div>
                        </div>
                        )

                    }   

                    </div>
                    <UserModal/>
                                           
                </div>
                </main>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        basicModal: currentState.basicModal,
        isModalChanged: currentState.isModalChanged,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showUserModal: (data) => {
            dispatch({ type: 'basic.modal.open', data : data });
        },
        updateUserComponentFalse: () =>{
            dispatch({type: 'modal.changed.false'})
        }

    };
}

export default connect(mapStateToProps, mapActionsToProps)(UserComponent);