import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap4-modal';

import * as service from '../../../../Service/service';

class JobIdModal extends Component {
    state = {
        imagePreview: '',
        file: '',
        isSelected: false,
        isUploaded: false,
        uploadData: {}
    }

    clearSelected = () => {
        this.setState({
            ...this.state,
            imagePreview: '',
            file: '',
            isSelected: false
        })
    }

    onBackdropClick = () => {
        this.setState({
            ...this.state,
            imagePreview: '',
            file: '',
            isSelected: false,
            isUploaded: false,
            uploadData: {}
        })
        this.props.hideJobIdModal();
    }

    onFileSelect = (e) => {

        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                this.setState({
                    ...this.state,
                    imagePreview: reader.result,
                    file: file,
                    isSelected: true
                });
            };
            reader.readAsDataURL(file);
        }
        
    };

    uploadFile = () => {
        const formData = new FormData();
        formData.append("file", this.state.file);
        formData.append("documentType", "JOB_ID");
        console.log(this.state)

        service.uploadDocument(formData, this.props.username).then((response) => {
            this.setState({
                ...this.state,
                isUploaded: true,
                uploadData: response.data
            })
        }, (error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <Modal visible={this.props.kyc.showJobIdModal} onClickBackdrop={this.onBackdropClick}>
                <div className="modal-header">
                    <h5 className="modal-title">Upload Job Id Card</h5>
                </div>
                <div className="modal-body pt-0 pb-0" style={{ height: 'inherit' }}>
                    <div className="row" style={{ minHeight: '250px' }}>
                        <div className="col-12 m-auto">
                            <div className="row">
                                {
                                    this.state.isUploaded && this.state.isSelected && (
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <h5>Thank you for uploading document.</h5>
                                                    <span className="text-black-50">*It may take some time to verify documents.</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    !this.state.isSelected && (
                                        <div className="col-12 text-center mt-5 image-upload">

                                            <label htmlFor="file-input-jobid" className="cursor-pointer">
                                                <img height="100px" src="/svg/uploadFile.svg" />
                                            </label>

                                            <input onChange={this.onFileSelect} id="file-input-jobid" type="file" name="jobid" />

                                            <h5 className="mt-3">Select Job Id Card</h5>

                                            <span className="text-black-50">*( Allowed formats PDF, JPEG, PNG)</span>
                                        </div>
                                    )
                                }
                                {
                                    this.state.isSelected && !this.state.isUploaded && (
                                        <div className="col-12 text-center" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                                            <img className="img-fluid" src={this.state.imagePreview} alt="" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" style={{ display: this.state.isUploaded ? 'none' : 'block' }} onClick={this.uploadFile} className="btn btn-primary btn-sm" >Upload</button>
                    <button type="button" style={{ display: this.state.isUploaded ? 'none' : 'block' }} onClick={this.clearSelected} className="btn btn-light btn-sm" >Clear Selection</button>
                    <button type="button" onClick={this.onBackdropClick} className="btn btn-danger btn-sm" >Close</button>
                </div>
            </Modal>
        );
    }   
}

const mapStateToProps = (currentState) => {
    return {
        kyc: currentState.kyc,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        hideJobIdModal: () => {
            dispatch({ type: 'kyc.jobId.hide' });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(JobIdModal);