import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';

import * as service from '../../../Service/service';


class IssueModal extends Component {

    state = {
        comments:'',
        showInfo:false,
        isUpdated:false,
        message:'',
        infoType:''
    }

    onCommentsEnter=(e)=>{
        this.setState({
            ...this.state,
            comments: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    responseSubmit=()=>{
        this.setState({...this.state,isUpdated: false})

        let obj = {
            issueId: this.props.basicModal.data.issueId,
            response: this.state.comments 
        };
        service.submitIssueResponse(obj).then((response)=>{
            this.setState({
                ...this.state,
                isUpdated: false,
                showInfo: true,
            });

            if(response.data){
                this.setState({
                    message : 'Invoice Adjusted Successfully',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-success'
                })
                this.props.updateIssueComponentTrue();
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-danger'
                })
            }

        })
    }
    getResponse = (issueId,response)=>{

        let obj = {
            issueId: issueId,
            response: response 
        };
        service.submitIssueResponse(obj).then((response)=>{
            this.setState({
                ...this.state,
                isUpdated: true,
            });
        })
    }

    componentWillReceiveProps(){
        this.setState({comments:'',showInfo:false})    
    }

    render() {
        return (
            <Modal visible={this.props.basicModal.showModal} onClickBackdrop={this.props.closeIssueModal}>
                <div className="modal-header">
                    <h5 className="modal-title">IssueResponse</h5>
                </div>
                <div className="modal-body">

                    <div className="col-12">
                        <div className="alert alert-secondary" role="alert">
                            
                                <b>Issue Id : {this.props.basicModal.data.issueId}</b><br/>
                                <b>Issue Against : {this.props.basicModal.data.issueAgainst}</b><br/>
                                
                        </div>
                    
                                    <div>
                                       {
                                                this.state.showInfo && (
                                                    <div className={this.state.infoType} role="alert">
                                                        <span>{this.state.message}</span>
                                                    </div>
                                                )
                                        }
                                            <form>
                                                
                                                <div className="mt-2">
                                                    <label htmlFor="comments" className="">Comments</label><br/>
                                                    <textarea style={{border:'1px solid #D3D3D3',width: '100%'}} value={this.state.comments} onChange={this.onCommentsEnter} className="form-control" rows="3" placeholder="Enter Response"></textarea>
                                                </div>
                                            </form>
                                                
                                    </div>
                             
                            
                    </div>

                </div>
                <div className="modal-footer">
                    
                    <button  type="button" className="btn btn-success" disabled={this.state.isUpdated} onClick={()=> this.responseSubmit()}>
                        Submit Response
                    </button>
                    <button type="button" className="btn btn-primary" onClick={this.props.closeIssueModal}>
                        Close
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        basicModal: currentState.basicModal,
        isModalChanged: currentState.isModalChanged,
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeIssueModal: () => {
            dispatch({ type: 'basic.modal.close' })
        },
        updateIssueComponentTrue: () =>{
            dispatch({type: 'modal.changed.true'})
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(IssueModal);