import React, { Component } from 'react';

import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';

import * as service from '../../../Service/service';

class PaymentModal extends Component {
    state = {
        paymentDetails:[],
    }

    render() {
        return (
            <Modal visible={this.props.invoiceDetails.showModal} onClickBackdrop={this.props.closeInvoiceModal}>
                <div className="modal-header">
                    <h5 className="modal-title">Payment Details</h5>
                </div>
                <div className="modal-body">

                    <div className="col-12">
                        <div className="alert alert-secondary" role="alert">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b>Username</b></td> 
                                        <td>: {this.props.invoiceDetails.data.username}</td> 
                                    </tr>
                                    <tr>
                                        <td><b>Email</b></td> 
                                        <td>: {this.props.invoiceDetails.data.email}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Contact</b></td> 
                                        <td>: {this.props.invoiceDetails.data.contact}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    
                            
                    </div>

                </div>
                <div className="modal-footer">

                    <button type="button" className="btn btn-primary" onClick={this.props.closeInvoiceModal}>
                        Close
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        invoiceDetails: currentState.invoiceDetails,
        isInvoiceChanged: currentState.isInvoiceChanged,
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeInvoiceModal: () => {
            dispatch({ type: 'invoice.modal.close' })
        },
        updateInvoiceComponentTrue: () =>{
            dispatch({type: 'invoice.changed.true'})
        }
    };
}
 
export default connect(mapStateToProps, mapActionsToProps)(PaymentModal);