import React, { Component } from 'react';
import H2Title from './../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import HeaderComponent from './../Generic/Header/HeaderComponent';
import {CSVLink} from 'react-csv';
import * as service from './../../Service/service';
import { connect } from 'react-redux';
import InvoiceModal from '../Generic/Modal/InvoiceModal';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';

class InvoiceComponent extends Component {

    state = {
        invoiceList: [],  
        isLoaded: false,
        orderNumber:'',
        columns: [
            
            {
              label: 'Invoice Number',
              field: 'invoiceNumber',
              width: 100,
            },
            {
              label: 'Amount',
              field: 'amount',
              sort: 'disabled',
              width: 100,
            },
            {
              label: 'Generated Date',
              field: 'generatedOn',
              width: 100,
            },
            {
               label: 'Is Paid',
               field: 'status',
               width: 100,
            },
            {
                label: 'Username',
                field: 'username',
                width: 100,
             }  
             ,
            {
                label: 'Discount',
                field: 'discount',
                width: 100,
             }  
             ,
            {
                label: 'View More',
                field: 'more',
                width: 100,
             }        
          ],
        rows: [],
       
    }
    constructor(props) {
        super(props);

        this.state = ({
            ...this.state,
            orderNumber: props.match.params.orderNumber
        });

    }


    listAllInvoices = () => {
       
        service.listInvoiceByOrderNumber(this.state.orderNumber).then((response) => {

            var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
    
            else {this.setState({
                ...this.state,
                invoiceList: response.data,
                isLoaded: true,
            });
         
                this.state.invoiceList.map((invoice)=>{
                    let row = {
                        invoiceNumber:invoice.invoiceNumber,
                        amount:invoice.amount,
                        generatedOn: invoice.generatedOn!=null?invoice.generatedOn.slice(0,10):'-',
                        status:invoice.isPaid == true ? 'Paid' : 'Pending',
                        username : invoice.username,
                        discount : invoice.discount,
                        more : <a className='text-primary' style={{cursor:'pointer'}}  onClick={()=>this.viewMore(invoice.invoiceNumber,invoice.isPaid,invoice.paymentId,invoice.amount)}>View More</a>
                    }
                    this.state.rows.push(row);
                })
            
            this.getTableData();    
            }
        }, (error) => {
            console.log(error);
        });
    }
    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    viewMore = (invoiceNumber,isPaid,paymentId,amount)=>{
        this.props.showInvoiceModal({
            invoiceNumber: invoiceNumber,
            isPaid:isPaid,
            paymentId:paymentId,
            invoiceAmount:amount
       })
    }
    componentDidMount() {

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Invoices', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        if(localStorage.hasOwnProperty("keycloak")==true){
            this.listAllInvoices();
        }
        
    }
    componentDidUpdate(){
        if( this.props.isInvoiceChanged.isUpdate==true){
            this.setState({
                ...this.state,
                rows: [],
            })
    
            this.listAllInvoices(); 
            this.props.updateInvoiceComponentFalse();
        }
    }


    render() {
        return (
            <React.Fragment>
            <HeaderComponent/>
            <BreadCrumbs />

            <div className='container mt-3 mb-3'>
                    <div className="col-12 mt-3 mb-3 ">
                    <H2Title value="Invoices" />
 
                                {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                

                    {   
                        this.state.isLoaded &&(
                        <div className='col-12 mt-2 mb-2' style={{border:'1px solid #C0C0C0',borderRadius:'5px'}}>
                        <div className="mt-3 mb-3">
                        <MDBDataTableV5 paging={false} info={false}  barReverse striped bordered hover noBottomColumns responsive  data={this.state.datatable} />
                        </div>
                        </div>
                        )

                    }   

                    </div>
                    
                                           
                </div>
                
                <InvoiceModal/>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        invoiceDetails: currentState.invoiceDetails,
        isInvoiceChanged: currentState.isInvoiceChanged,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showInvoiceModal: (data) => {
            dispatch({ type: 'invoice.modal.open', data : data });
        },
        updateInvoiceComponentFalse: () =>{
            dispatch({type: 'invoice.changed.false'})
        }

    };
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceComponent);