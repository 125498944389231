import React, { Component } from "react";
import H2Title from "./../../Generic/Element/Heading";
import { MDBDataTableV5 } from "mdbreact";
import * as service from "./../../../Service/service";
import { connect } from "react-redux";
import InvoiceModal from "./../../Generic/Modal/InvoiceModal";
import keys from "./../../../Config/config.json";
import Pagination from "../../Generic/Element/Pagination";
import AccountHeader from "../../Generic/Header/AccountHeader";
import BreadCrumbs from "../../Generic/BreadCrumbs/BreadCrumbs";
import * as jwt_decode from "jwt-decode";

class AllInvoiceComponent extends Component {
  state = {
    invoiceList: [],
    isLoaded: false,
    orderNumber: "",
    pageSize: 20,
    currentPage: 1,
    totalCount: 0,
    isPageLoaded: false,
    paginateLoad: false,
    sortBy: "All",
    search: "",
    startDate: "",
    endDate: "",
    searchFun: false,
    columns: [
      {
        label: "Invoice Number",
        field: "invoiceNumber",
        width: 100,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Generated Date",
        field: "generatedOn",
        width: 100,
      },
      {
        label: "Is Paid",
        field: "status",
        width: 100,
      },
      {
        label: "Username",
        field: "username",
        width: 100,
      },
      {
        label: "Discount",
        field: "discount",
        width: 100,
      },
      {
        label: "Interest",
        field: "interest",
        width: 100,
      },
      {
        label: "Final Amount",
        field: "finalAmount",
        width: 100,
      },
      {
        label: "View More",
        field: "more",
        width: 100,
      },

    ],
    rows: [],
  };

  onSortBy = (event) => {
    this.setState(
      {
        ...this.state,
        sortBy: event.target.value,
        rows: [],
        invoiceList: [],
        isLoaded: false,
        currentPage: 1,
      },
      () => {
        this.listAllInvoices();
        console.log(this.state);
      }
    );
  };

  onSearchBy = (event) => {
    this.setState(
      {
        ...this.state,
        searchBy: event.target.value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  onSearchEnter = (event) => {
    this.setState(
      {
        ...this.state,
        startDate: "",
        endDate: "",
        search: event.target.value,
        currentPage: 1,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  onStartDate = (e) => {
    this.setState(
      {
        ...this.state,
        search: "",
        startDate: e.target.value,
      },
      () => {
        console.log(this.state);
      }
    );
  };
  onEndDate = (e) => {
    this.setState(
      {
        ...this.state,
        search: "",
        endDate: e.target.value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  listAllInvoices = () => {
    var decoded = jwt_decode(localStorage.keycloak);
    if (JSON.parse(decoded.preferred_username != keys.admin_token)) {
      alert("Unauthorized access");
      localStorage.removeItem("keycloak");
      window.location.href = window.location.origin + "?url=" + window.location;
    } else {
      service
        .allInvoices(
          this.state.sortBy,
          this.state.search,
          this.state.startDate,
          this.state.endDate,
          this.state.currentPage,
          this.state.pageSize
        )
        .then(
          (response) => {
            if (response.data.length == 0) {
              this.setState({ isLoaded: false, rows: [] });
            }

            if (response.data.length != 0) {
              this.setState({
                ...this.state,
                invoiceList: response.data,
                isLoaded: true,
                paginateLoad: false,
                searchFun: false,
                totalcount: response.headers["x-totalcount"],
              });

              this.state.invoiceList.map((invoice) => {
                let row = {
                  invoiceNumber: invoice.invoiceNumber,
                  amount: invoice.amount.toFixed(2),
                  generatedOn: invoice.generatedOn.slice(0, 10),
                  status: invoice.isPaid == true ? "paid" : "pending",
                  username: invoice.username,
                  discount: invoice.discount,
                  interest: invoice.interestGenerated,
                  finalAmount : (invoice.amount + invoice.interestGenerated).toFixed(2),
                  more: (
                    <a
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.viewMore(
                          invoice.invoiceNumber,
                          invoice.isPaid,
                          invoice.paymentId,
                          invoice.amount
                        )
                      }
                    >
                      View More
                    </a>
                  ),
                };
                this.state.rows.push(row);
              });

              this.getTableData();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };
  handlePageChange = (page) => {
    this.setState({
      ...this.state,
      orders: [],
      rows: [],
      paginateLoad: true,
      currentPage: page,
      isPageLoaded: true,
      isLoaded: false,
    });
  };

  getTableData() {
    this.setState({
      ...this.state,
      datatable: {
        columns: this.state.columns,
        rows: this.state.rows,
      },
    });
  }

  viewMore = (invoiceNumber, isPaid, paymentId, amount) => {
    this.props.showInvoiceModal({
      invoiceNumber: invoiceNumber,
      isPaid: isPaid,
      paymentId: paymentId,
      invoiceAmount: amount,
    });
  };
  componentDidMount() {
    this.props.updateBreadcumbs({
      navigation: [
        { name: "Home", link: "/" },
        { name: "Invoices", link: "#" },
      ],
      showSidebar: false,
      sidebar: [],
    });

    if (localStorage.hasOwnProperty("keycloak") == false) {
      alert("Login to view");
      window.location.href = window.location.origin + "?url=" + window.location;
    } else if (localStorage.hasOwnProperty("keycloak") == true) {
      this.listAllInvoices();
    }
  }
  componentDidUpdate() {
    if (this.props.isInvoiceChanged.isUpdate == true) {
      this.setState({
        ...this.state,
        rows: [],
        currentPage: 1,
      });

      this.listAllInvoices();
      this.props.updateInvoiceComponentFalse();
    }

    if (this.state.searchFun) {
      this.listAllInvoices();
    }

    if (this.state.isPageLoaded == true) {
      this.listAllInvoices();
      this.setState({ isPageLoaded: false });
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ rows: [], invoiceList: [], isLoaded: false });
      this.listAllInvoices();
    }
  };

  findByDate = (click) => {
    if (click == "click") {
      this.setState({
        ...this.state,
        click: "",
        rows: [],
        currentPage: 1,
        invoiceList: [],
        isLoaded: false,
      });
      this.listAllInvoices();
    }
  };

  searchByFunc = () => {
    if (
      this.state.search != "" ||
      (this.state.startDate != "" && this.state.endDate != "")
    ) {
      this.setState({
        search: "",
        rows: [],
        currentPage: 1,
        invoiceList: [],
        isLoaded: false,
        startDate: "",
        endDate: "",
        searchFun: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
  <AccountHeader />
  <BreadCrumbs />
  <main role="main" className="container mt-3">
    <div className="row">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <H2Title value="Invoices" />
          <a
            className="btn btn-primary text-light mt-2 mt-md-0"
            href={window.location.origin + "/newInvoice"}
          >
            Invoice Operations
          </a>
        </div>

        <div className="row element-border mt-3 mb-3 mx-1" style={{ minHeight: "250px" }}>
          {/* Loading Spinner */}
          {!this.state.isLoaded && (
            <div className="col-12 text-center mt-5">
              <div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <span className="text-black-50 d-block mt-2">Checking backend...</span>
            </div>
          )}

          {/* Data Display */}
          {this.state.isLoaded && this.state.rows.length !== 0 && (
            <div className="col-12 mt-2 mb-2">
              {/* Filters */}
              <div className="row align-items-center">
                {/* View By */}
                <div className="col-md-4 col-12 mb-2">
                  <label className="font-weight-bold">View By:</label>
                  <select
                    value={this.state.sortBy}
                    onChange={this.onSortBy}
                    className="form-control"
                  >
                    <option>All</option>
                    <option>Paid</option>
                    <option>Unpaid</option>
                  </select>
                </div>

                {/* Search By */}
                <div className="col-md-4 col-12 mb-2">
                  <label className="font-weight-bold">Search By:</label>
                  <select
                    value={this.state.searchBy}
                    onChange={this.onSearchBy}
                    className="form-control"
                  >
                    <option>All</option>
                    <option>Field</option>
                    <option>Date</option>
                  </select>
                </div>

                {/* Search Input */}
                {this.state.searchBy === "Field" && (
                  <div className="col-md-4 col-12 mb-2">
                    <label className="font-weight-bold">Search:</label>
                    <input
                      type="text"
                      value={this.state.search}
                      onChange={this.onSearchEnter}
                      onKeyDown={this._handleKeyDown}
                      className="form-control"
                      placeholder="Username/Invoice"
                    />
                  </div>
                )}

                {/* Date Filters */}
                {this.state.searchBy === "Date" && (
                  <div className="col-12 d-flex flex-wrap align-items-center mb-2">
                    <label className="font-weight-bold mr-2">Start:</label>
                    <input
                      value={this.state.startDate}
                      onChange={this.onStartDate}
                      type="date"
                      className="form-control mr-2"
                      style={{ width: "150px" }}
                    />
                    <label className="font-weight-bold mr-2">End:</label>
                    <input
                      value={this.state.endDate}
                      type="date"
                      onChange={this.onEndDate}
                      className="form-control mr-2"
                      style={{ width: "150px" }}
                    />
                    <button className="btn btn-primary btn-sm" onClick={() => this.findByDate("click")}>
                      Submit
                    </button>
                  </div>
                )}
              </div>

              {/* Table */}
              <div className="mt-3 mb-1">
                <MDBDataTableV5
                  searching={false}
                  paging={false}
                  info={false}
                  barReverse
                  striped
                  bordered
                  hover
                  noBottomColumns
                  responsive
                  data={this.state.datatable}
                />
              </div>

              {/* Pagination */}
              <div className="ml-2">
                <Pagination
                  itemsCount={parseInt(this.state.totalcount)}
                  pageSize={this.state.pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={this.state.currentPage}
                  pageLoad={this.state.paginateLoad}
                />
              </div>
            </div>
          )}
        </div>

        <InvoiceModal />
      </div>
    </div>
  </main>
</React.Fragment>

    );
  }
}

const mapStateToProps = (currentState) => {
  return {
    invoiceDetails: currentState.invoiceDetails,
    isInvoiceChanged: currentState.isInvoiceChanged,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    showInvoiceModal: (data) => {
      dispatch({ type: "invoice.modal.open", data: data });
    },
    updateInvoiceComponentFalse: () => {
      dispatch({ type: "invoice.changed.false" });
    },
    updateBreadcumbs: (data) => {
      dispatch({ type: "breadcrumbs.update", data: data });
    },
  };
};

export default connect(mapStateToProps, mapActionsToProps)(AllInvoiceComponent);
