import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';

import * as service from './../../../../Service/service';
import HeaderComponent from '../../../Generic/Header/HeaderComponent';
import FooterComponent from '../../../Generic/Footer/FooterComponent';
import BreadCrumbs from '../../../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
class ForgotComponent extends Component {

    state = {
        email: '',
        loading: false,
        message: 'Reset Password',
        info: false
    }

    handleInput = (e) => {
        this.setState({
            ...this.state,
            email: e.target.value
        })
    }

    forgotMail = () => {
        this.setState({
            ...this.state,
            message: 'Please wait .',
            loading: true
        });
        service.passwordResetMail(this.state.email).then((response) => {
            if (response.data) {

                this.setState({
                    ...this.state,
                    message: 'Reset Password',
                    loading: false,
                    info : true
                });

            }
        }, (error) => {
            console.log(error);
        });
    }

    componentDidMount(){
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Forget Password', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    render() {
        return (
            <React.Fragment>
                <div>
                <HeaderComponent/>
                <BreadCrumbs/>
                <main role="main" className="container m-auto">

                    <div className="row mt-2" style={{ height: '100%' }}>
                        <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                        </div>

                        <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>

                        {
                    this.state.info && (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>Success!</strong> Please check your registered email for instructions.
                                <button type="button" onClick={
                                    () => this.setState({
                                        ...this.state,
                                        info : false
                                    })
                                 } className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    )
                }

                {
                    !this.state.info && (
                        <div>
                            <div className="mt-5"><H2Title value="Get your password back" /></div>
                            <div className="form-group mt-3">
                                <input value={this.state.email} onChange={this.handleInput} type="email" className="form-control" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder="Enter email Id" />
                            </div>
                            <div className="form-group">
                                <small id="emailHelp" className="form-text text-muted text-left"
                                >We will send password reset link to registered email Id.
                                </small>
                            </div>
                        </div>
                    )
                }

                <button type="button" onClick={this.forgotMail} className="btn btn-primary btn-sm btn-block">
                    {
                        this.state.loading && (
                            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        )
                    }
                    {this.state.message}
                </button>

                <a type="button" href={window.location.origin} className="btn btn-success btn-sm btn-block">Sign In</a>
                        </div>
                    </div>
                </main>
                <FooterComponent/>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        selectCityClose: () => {
            dispatch({ type: 'user.city.hide' });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ForgotComponent);