import React, { Component } from 'react';
import H2Title from './../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import HeaderComponent from './../Generic/Header/HeaderComponent';
import * as service from './../../Service/service';
import { connect } from 'react-redux';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';


class PaymentComponent extends Component {

    state = {
        paymentList: [],  
        isLoaded: false,
        username:'',
        columns: [
            {
              label: 'Payment Against',
              field: 'paymentAgainst',
              sort: 'disabled',
              width: 150,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Order Number',
                
              },
            },
            {
              label: 'Payment Mode',
              field: 'paymentMode',
              width: 270,
            },
            {
              label: 'Transaction Id',
              field: 'transactionId',
              sort: 'disabled',
              width: 200,
            },
            {
              label: 'Amount Billed',
              field: 'amount',
              width: 100,
            },
            {
               label: 'Transaction Date',
               field: 'transactionDate',
               width: 100,
            },
            {
                label: 'Status',
                field: 'status',
                width: 100,
             }        
          ],
        rows: [],
       
    }
    constructor(props) {
        super(props);

        this.state = ({
            ...this.state,
            paymentId: props.match.params.paymentId
        });

    }

    listAllPayments = (paymentId) => {

        service.listPaymentById(paymentId).then((response) => {

            this.setState({
                ...this.state,
                paymentList: response.data,
                isLoaded: true,
            });
            
            var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }


            else if(response.data!=null){
                    let row = {
                        paymentAgainst:this.state.paymentList.paymentAgainst,
                        paymentMode: this.state.paymentList.method,
                        transactionId:this.state.paymentList.razorPayId,
                        amount:this.state.paymentList.amount/100,
                        transactionDate: (this.state.paymentList.createdAt!=null)?this.state.paymentList.createdAt.slice(0,10):'',
                        status : this.state.paymentList.status,
                    }
                    this.state.rows.push(row);
            }
            this.getTableData();
        }, (error) => {
            console.log(error);
        });
    }
    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    componentWillMount() {

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Payment', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true){
            this.listAllPayments(this.state.paymentId);
        }
    }


    render() {
        return (
            <React.Fragment>
            <HeaderComponent/>
            <BreadCrumbs/>
            <div className='container mt-3 mb-3'>
                    <div className="col-12 mt-3 mb-3">
                    <H2Title value="Payments" />

                               {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }


                    {   
                        this.state.isLoaded &&(
                        <div className='col-12 mt-2 mb-2' style={{border:'1px solid #C0C0C0',borderRadius:'5px'}}>
                        <div className="mt-3 mb-3">
                            
                        <MDBDataTableV5 paging={false} info={false}  striped bordered hover noBottomColumns responsive={true}  data={this.state.datatable} />
                        </div>
                        </div>
                        )

                    }   
                    

                    </div>
                          
                        

                    
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userInfo: currentState.userState.userInfo
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(PaymentComponent);