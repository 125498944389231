import React, { Component } from 'react';

import H2Title from './../../Generic/Element/Heading';
import * as service from '../../../Service/service';
import * as jwt_decode from 'jwt-decode';
import keys from './../../../Config/config.json';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import AccountHeader from '../../Generic/Header/AccountHeader';

class ReportComponent extends Component {
    state = { 
        choice: 'Select the choice',
        isLoaded: false,
        isDisabled:false,
        endDate:'',
        startDate:'',
        dataArray:[],
     }


    onChoiceSelect=(e)=>{
        this.setState({
            ...this.state,
            choice: e.target.value,
            isLoaded:false,
            isDisabled:false
        }, () => {
            
            console.log(this.state);
        });
    }

    onStartChange=(e)=>{
        this.setState({
            ...this.state,
            startDate: e.target.value
        }, () => {
            console.log(this.state);
        });
    }


    onEndChange=(e)=>{
        this.setState({
            ...this.state,
            endDate: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    handleReportData=()=>{

        if(this.state.choice!='Select the choice'){
            this.listData();
        }
        
    }


    listData=()=>{

        this.setState({...this.state,isDisabled:true,isLoaded:false})

                if(this.state.choice=='Orders'){
                    var choiceResponse= service.getAllOrders(this.state.startDate,this.state.endDate)
                }
                else if(this.state.choice=='Invoices'){
                    var choiceResponse= service.getAllInvoices(this.state.startDate,this.state.endDate)
                }
                else if(this.state.choice=='Payments'){
                    var choiceResponse= service.getAllPayments(this.state.startDate,this.state.endDate)
                }

                
                choiceResponse.then((response)=>{

                    response.data.map((order)=>{        
                        this.state.dataArray.push(order);
                    })

                    if(this.state.dataArray.length==0){
                        this.setState({isLoaded:false,dataArray:[],isDisabled:false})
                    }

                    else if(this.state.dataArray.length!=0){
                    this.setState({
                        ...this.state,
                        endDate:'',
                        startDate:'',
                        isDisabled:false,
                        isLoaded:true,
                    });
                    }
                    })
                
            }
            
    

    componentDidMount(){

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Report', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }
    }

    afterReport=()=>{
        this.setState({
            ...this.state,
            isLoaded:false,
            dataArray:[]
        })
    }
    
    render() { 
        return (

        <React.Fragment>
            <AccountHeader/>
            <BreadCrumbs/>
            <div className='container mt-3 mb-3'>
                           
                           <div className="row mt-5" style={{ height: '100%' }}>
                               <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                            </div>
       
                            <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>
                                        <H2Title value="Report" /><br/>
                                    <div className='container' >
                                   
                                        <label htmlFor="choice" className="font-weight-bold mt-2">Report For </label>
                                        <select value={this.state.choice} onChange={this.onChoiceSelect}  className="browser-default custom-select mb-2" id="choice" placeholder="Select the choice">
                                            <option>Select the choice</option>
                                            <option>Orders</option>
                                            <option>Invoices</option>
                                            <option>Payments</option>
                                        </select>
                                     
                                    { 

                                    this.state.choice!='Select the choice' && (
                                        <div>
                                        <form className="mt-2">
                                            
                                            <div className="mt-2">
                                                <span htmlFor="description" className="font-weight-bold">Start</span>
                                                <input type="date" onChange={this.onStartChange} className="form-control" value={this.state.startDate}/>
                                                <span htmlFor="end" className="font-weight-bold">End</span>
                                                <input type="date" onChange={this.onEndChange} className="form-control" value={this.state.endDate}/>
                                            </div>
                                        </form>

                                        {
                                            !this.state.isLoaded && (
                                            <button style={{width: '100%'}} type="button" className="btn btn-primary mt-2" onClick={this.handleReportData} disabled={this.state.isDisabled || this.state.choice=='Select the choice'}>
                                                {
                                                    this.state.isDisabled?<div><span className="ml-2">Loading &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Submit"
                                                }
                                            </button>
                                            )
                                        }
                                        {
                                            this.state.isLoaded &&(
                                                <CSVLink data={this.state.dataArray}><button style={{width: '100%'}} onClick={this.afterReport} className="btn btn-success mt-2">Download Report</button></CSVLink>
                                            )
                                        }
                                        </div>)
                                    }
                                    </div>

                            </div>
                           </div>
                           
                               
                           </div>
                           

        </React.Fragment>  
        
        );
    }
}
const mapStateToProps = (currentState) => {
    return {};
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }

    };
} 
export default connect(mapStateToProps, mapActionsToProps)(ReportComponent);