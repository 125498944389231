import React, { Component } from 'react';
import H2Title from '../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import * as service from '../../Service/service';
import { connect } from 'react-redux';
import IssueModal from '../Generic/Modal/IssueModal';
import HeaderComponent from '../Generic/Header/HeaderComponent';
import * as jwt_decode from 'jwt-decode';
import keys from './../../Config/config.json';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';


class IssueResponseComponent extends Component {
    state = {
        issueList: [],  
        isLoaded: false,
        issueResponse:'',
        issueId:'',
        issueAgainst:'',
        columns: [
            {
              label: 'Issue Id',
              field: 'issueId',
              sort: 'disabled',
              width: 100,
              attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Issue Id',       
              },
            },
            {
              label: 'Issue Type',
              field: 'issueType',
              width: 100,
            },
            
            {
               label: 'Reason',
               field: 'reason',
               width: 150,
            },
            {
                label: 'Description',
                field: 'description',
                width: 100,
             },
             {
                label: 'Response',
                field: 'issueResponse',
                width: 200,
             },
          ],
        rows: [],
       
      }

    onResponseEnter=(e)=>{
        this.setState({
            ...this.state,
            issueResponse: e.target.value
        }, () => {
            console.log(this.state);
        });

    }

    constructor(props) {
        super(props);

        this.state = ({
            ...this.state,
            issueAgainst: props.match.params.issueAgainst

        });
        console.log(this.state.issueAgainst)

    }

    ListAllIssues = () => {

        service.getAllIssues(this.state.issueAgainst).then((response) => {

            this.setState({
                ...this.state,
                issueList: response.data,
                isLoaded: true,
            });

            var decoded = jwt_decode((localStorage.keycloak))
            if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
                alert("Unauthorized access");
                localStorage.removeItem('keycloak')
                window.location.href=window.location.origin+'?url='+window.location
            }

            else if(response.data!=null){
                this.state.issueList.map((issue)=>{
                    let row = {
                        issueId:issue.issueId,
                        issueType:issue.issueType,
                        reason: issue.reason,
                        description : issue.description,
                        issueResponse : <a className='text-primary' style={{cursor:'pointer'}}  onClick={()=>this.viewResponseModal(issue.issueId,issue.issueItemId)}>Response</a>
                    }
                    this.state.rows.push(row);
                })
            }
            this.getTableData();
        }, (error) => {
            console.log(error);
        });
    }

    viewResponseModal = (issueId,issueAgainst)=>{
        this.props.showIssueModal({
            issueId: issueId,
            issueAgainst:issueAgainst,
       })
    }
    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }
    


    componentWillMount() {

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Issues', link: '#' }],
            showSidebar: false,
            sidebar: []
        });

        if(localStorage.hasOwnProperty("keycloak")==false){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true){
            this.ListAllIssues();
        }
    }

    componentDidUpdate(){
        if( this.props.isModalChanged.isUpdate==true){
            this.setState({
                ...this.state,
                rows: [],
            })
        this.ListAllIssues();
        this.props.updateInvoiceComponentFalse();
        }

    }
    render() { 
        return ( 
            <React.Fragment>
                <HeaderComponent/>
                <BreadCrumbs/>
                <div className='container mt-3 mb-3'>
                    <div className="col-12 mt-3 mb-3">
                    <H2Title value="Issue Response" />
        
                                {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                    {   
                        this.state.isLoaded &&(
                        <div className='col-12 mt-2 mb-2' style={{border:'1px solid #C0C0C0',borderRadius:'5px'}}>
                        <div className="mt-3 mb-3">

                        <MDBDataTableV5 paging={false} info={false}  barReverse striped bordered hover noBottomColumns responsive data={this.state.datatable} />
                        </div>
                        </div>
                        )

                    }   

                    </div>
                    
                </div>   
                <IssueModal/>
            </React.Fragment>
         );
    }
}
const mapStateToProps = (currentState) => {
    return {
        basicModal: currentState.basicModal,
        isModalChanged: currentState.isModalChanged,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        showIssueModal: (data) => {
            dispatch({ type: 'basic.modal.open', data : data });
        },
        updateInvoiceComponentFalse: () =>{
            dispatch({type: 'modal.changed.false'})
        }

    };
}
 
export default connect(mapStateToProps, mapActionsToProps)(IssueResponseComponent);