import Axios from 'axios';

const APPLICATION_URI = "https://v2.voorent.com";

// Hardcoded endpoints
export function printInvoice(invoiceNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/print/${invoiceNumber}`);
}

export function listOrders(status, search, startDate, endDate, currentPage, pageSize) {
    return Axios.get(`${APPLICATION_URI}/admin/order/all/${status}?search=${search}&startDate=${startDate}&endDate=${endDate}&currentPage=${currentPage}&pageSize=${pageSize}`);
}

export function isOrderUnpaid(orderNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/unpaid/${orderNumber}`);
}

export function invoiceMarkPaid(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/invoice/mark/paid`, obj);
}

export function listInvoiceByOrderNumber(orderNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/orderNumber/${orderNumber}`);
}

export function allInvoices(sortBy, search, startDate, endDate, currentPage, pageSize) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/all/${sortBy}?search=${search}&startDate=${startDate}&endDate=${endDate}&currentPage=${currentPage}&pageSize=${pageSize}`);
}

export function listPaymentById(paymentId) {
    return Axios.get(`${APPLICATION_URI}/admin/payment/info/${paymentId}`);
}

export function getOrderByAddressId(addressId) {
    return Axios.get(`${APPLICATION_URI}/admin/order/addressId/${addressId}`);
}

export function orderInfoByOrderNumber(orderNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/order/info/${orderNumber}`);
}

export function changePassword(data) {
    return Axios.post(`${APPLICATION_URI}/user/user/changePassword`, data);
}

export function cartProductInfo(slugs) {
    return Axios.post(`${APPLICATION_URI}/admin/product/cartProductInfo`, slugs);
}

export function checkAddress(addressId) {
    return Axios.get(`${APPLICATION_URI}/user/address/check/${addressId}`);
}

export function ordersInfo() {
    return Axios.get(`${APPLICATION_URI}/admin/order/info/all`);
}

export function listProcessingOrders() {
    return Axios.get(`${APPLICATION_URI}/admin/order/processed`);
}

export function markOrderDelivered(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/order/mark/delivered`, obj);
}

export function markOrderCancelled(orderNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/order/mark/cancelled/${orderNumber}`);
}

export function submitInvoiceDetails(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/invoice/adjust`, obj);
}

export function submitReturnOrderDetails(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/order/mark/returned`, obj);
}

export function getAllIssues(issueAgainst) {
    return Axios.get(`${APPLICATION_URI}/admin/issues/all/${issueAgainst}`);
}

export function sendPendingMail(invoiceNumber) {
    return Axios.get(`${APPLICATION_URI}/mailer/pending/invoice/${invoiceNumber}`);
}

export function updateInvoiceMonth(invoiceNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/update/month/${invoiceNumber}`);
}

export function submitIssueResponse(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/issues/submit/response`, obj);
}

export function changeOrderAddressId(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/order/update/address`, obj);
}

export function updateUserPassword(obj) {
    return Axios.post(`${APPLICATION_URI}/user/user/changePassword`, obj);
}

export function checkEmail(email) {
    return Axios.get(`${APPLICATION_URI}/user/checkEmail/${email}`);
}

export function checkUsername(username) {
    return Axios.get(`${APPLICATION_URI}/user/checkUsername/${username}`);
}

export function getUserInfo() {
    return Axios.get(`${APPLICATION_URI}/user/user/userInfo`);
}

export function listAllUsers(search, currentPage, pageSize) {
    return Axios.get(`${APPLICATION_URI}/users/user/getAll?search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`);
}

export function passwordReset(obj) {
    return Axios.post(`${APPLICATION_URI}/user/user/password/update`, obj);
}

export function passwordResetMail(email) {
    return Axios.get(`${APPLICATION_URI}/user/user/password/reset/${email}`);
}

export function registerUser(userObj) {
    return Axios.post(`${APPLICATION_URI}/user/user/add`, userObj);
}

export function listAddressesByUsername(username) {
    return Axios.get(`${APPLICATION_URI}/users/address/get/username/${username}`);
}

export function listProductByChoice(choice) {
    return Axios.get(`${APPLICATION_URI}/admin/product/choice/${choice}`);
}

export function updateProductByChoice(choice, name) {
    return Axios.get(`${APPLICATION_URI}/admin/product/choice/update/${choice}/${name}`);
}

export function getAllOrders(startDate, endDate) {
    return Axios.get(`${APPLICATION_URI}/admin/order/csv/all?startDate=${startDate}&endDate=${endDate}`);
}

export function getAllInvoices(startDate, endDate) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/csv/all?startDate=${startDate}&endDate=${endDate}`);
}

export function getAllPayments(startDate, endDate) {
    return Axios.get(`${APPLICATION_URI}/admin/payment/csv/all?startDate=${startDate}&endDate=${endDate}`);
}

export function listAllPayments(search, currentPage, pageSize) {
    return Axios.get(`${APPLICATION_URI}/admin/payment/getAll?search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`);
}

export function userByUsername(username) {
    return Axios.get(`${APPLICATION_URI}/users/user/getBy/username/${username}`);
}

export function getSubCategoryByCategory(categoryName) {
    return Axios.get(`${APPLICATION_URI}/admin/product/getSubCategory/${categoryName}`);
}

export function addNewProduct(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/product/add/new`, obj);
}

export function addNewInvoices(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/invoice/add/new`, obj);
}

export function generateInterest(interestDate) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/generateInterest/${interestDate}`);
}

export function monthlyInterest(interestDate) {
    return Axios.get(`${APPLICATION_URI}/admin/invoice/monthlyInterest/${interestDate}`);
}

export function sendNotification() {
    return Axios.get(`${APPLICATION_URI}/mailer/notification`);
}

export function listOrderProducts(orderNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/order/getProducts/${orderNumber}`);
}

export function adjustOrderProducts(obj) {
    return Axios.post(`${APPLICATION_URI}/admin/order/adjustProducts`, obj);
}

export function allIssues(sortBy, search, currentPage, pageSize) {
    return Axios.get(`${APPLICATION_URI}/admin/issues/all/issues/${sortBy}?search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`);
}

export function uploadDocument(obj, username) {
    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    return Axios.post(`${APPLICATION_URI}/users/kyc/uploadDocument/${username}`, obj, config);
}

export function getKycDocuments(username) {
    return Axios.get(`${APPLICATION_URI}/users/kyc/getAll/${username}`);
}

export function depositAdjust(orderNumber) {
    return Axios.get(`${APPLICATION_URI}/admin/deposit/refund/${orderNumber}`);
}

export function login(params) {
    return Axios.post(`${APPLICATION_URI}/auth/admin/login`, params);
}
