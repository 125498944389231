import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import { MDBDataTableV5 } from 'mdbreact';
import * as service from './../../../Service/service';
import { connect } from 'react-redux';
import Pagination from '../../Generic/Element/Pagination';
import PaymentModal from '../../Generic/Modal/PaymentModal';
import AccountHeader from '../../Generic/Header/AccountHeader';
import BreadCrumbs from '../../Generic/BreadCrumbs/BreadCrumbs';
import * as jwt_decode from 'jwt-decode';
import keys from './../../../Config/config.json';

class AllPaymentComponent extends Component {
 

        state = {
            paymentList: [],  
            paymentDetails:{},
            isLoaded: false,
            search:'',    
            pageSize:20,
            currentPage :1 ,
            totalCount: 0,
            isPageLoaded: false,
            paginateLoad:false,
            isBack:false,
        
            columns: [
                {
                  label: 'Payment Against',
                  field: 'paymentAgainst',
                  sort: 'disabled',
                  width: 150,
                  attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Payment Against',
                    
                  },
                },
                {
                  label: 'Payment Mode',
                  field: 'paymentMode',
                  width: 270,
                },
                {
                  label: 'Transaction Id',
                  field: 'transactionId',
                  sort: 'disabled',
                  width: 200,
                },
                {
                  label: 'Amount Billed',
                  field: 'amount',
                  width: 100,
                },
                {
                   label: 'Transaction Date',
                   field: 'transactionDate',
                   width: 100,
                },         
                
                {
                    label: 'Status',
                    field: 'status',
                    width: 100,
                 },
                 {
                    label: 'More',
                    field: 'more',
                    width: 100,
                 }
                 
              ],
            rows: [],
           
        }

    onSearchEnter = (event) => {
        this.setState({
            ...this.state,
            search: event.target.value,
            currentPage:1
        }, () => {
            console.log(this.state);
        });
    }

    viewMore = (username)=>{
        this.getPaymentUserDetails(username);
        
    }

    getModalDetails=(paymentDetails)=>{
        this.props.showInvoiceModal({
            username: paymentDetails.username,
            email: paymentDetails.email,
            contact: paymentDetails.contact
       })
    }

    getPaymentUserDetails=(username)=>{
       
        service.userByUsername(username).then((response)=>{
            this.setState({
                ...this.state,
                paymentDetails:response.data,   
            });
            this.getModalDetails(this.state.paymentDetails);

        })
    }

    listAllPayments = () => {
        var decoded = jwt_decode((localStorage.keycloak))
        if(JSON.parse(decoded.preferred_username!=keys.admin_token)){
            alert("Unauthorized access");
            localStorage.removeItem('keycloak')
            window.location.href=window.location.origin+'?url='+window.location
        }
        else{

        service.listAllPayments(this.state.search,this.state.currentPage,this.state.pageSize).then((response) => {

            this.setState({
                ...this.state,
                paymentList: response.data,
                isBack:false,
                isLoaded: true,
                paginateLoad: false,
                totalcount: response.headers['x-totalcount']
            });


            if(response.data.length==0){
                this.setState({isLoaded:true,rows:[]})
            }


            if(response.data.length!=0){
                this.state.paymentList.map((payment)=>{
                    let row = {
                        paymentAgainst:payment.paymentAgainst,
                        paymentMode: payment.method,
                        transactionId:payment.razorPayId,
                        amount:payment.amount/100,
                        transactionDate: (payment.createdAt!=null)?payment.createdAt.slice(0,10):'',
                        status : payment.status,
                        more: <a className='text-primary' style={{cursor:'pointer'}}  onClick={()=>this.viewMore(payment.username)}>View Details</a>
                    }

                    this.state.rows.push(row);
                })
            }
            this.getTableData();
        }, (error) => {
            console.log(error);
        });
    }
    }
    handlePageChange=page=>{
        this.setState({
            ...this.state,
            userList:[],
            rows:[],
            paginateLoad:true,
            currentPage:page,
            isPageLoaded:true,
            isLoaded:false,
        });

      }
    getTableData(){
        this.setState({
            ...this.state,
            datatable:{
                columns : this.state.columns,
                rows: this.state.rows
            }
        })
    }

    handleBack=()=>{
        this.setState({
            rows:[],
            paymentList:[],
            isBack:true,
            isLoaded:false,
            search:'',
            currentPage:1
        })
    }

    
    componentDidMount() {

        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: '/' }, { name: 'Payments', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
        
        if(localStorage.hasOwnProperty("keycloak")==false ){
            alert("Login to view");
            window.location.href=window.location.origin+'?url='+window.location
        }
        else if(localStorage.hasOwnProperty("keycloak")==true ){
            
            this.listAllPayments();
        }
        
    }
    componentDidUpdate(){

        if( this.props.isInvoiceChanged.isUpdate==true){
            this.setState({
                ...this.state,
                rows: [],
                currentPage:1
            })
    
            this.listAllPayments(); 
            this.props.updateInvoiceComponentFalse();
        }
        
        if(this.state.isPageLoaded==true){
            this.listAllPayments();
            this.setState({isPageLoaded:false});
        }
        if(this.state.isBack ==true){
            this.listAllPayments();
        }

    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({rows:[],userList:[],isLoaded:false})
            this.listAllPayments();
        }
      }


    render() {
        return (
            <React.Fragment>
                    <AccountHeader/>
                    <BreadCrumbs/>
                <main role="main" className="container row  mt-3" style={{marginLeft:'auto',marginRight:'auto'}}>
                    <div className="col-12">
                        <H2Title value="Payments" />

                        <div className="row element-border mt-3 mb-3 mr-1 ml-1" style={{ minHeight: '250px' }}>


                            {
                                !this.state.isLoaded && (<div className="col-12 m-auto">
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                        </div>
                                    </div>
                                </div>)
                            }
                                
                    {
                        this.state.isLoaded && this.state.rows.length==0 && (
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <img height="150px" src="./svg/order.svg" />
                                                <h5 className="mt-3 mb-5">No payments are available.</h5>
                                            </div>
                                        </div>
                                    </div>
                        )
                    }

                    {   
                        this.state.isLoaded && this.state.rows.length!=0 && (
                        <div className=' col-12 mt-2 mb-2'>
                            <div className='row mt-2'>

                            <input type='text' value={this.state.search} onChange={this.onSearchEnter} onKeyDown={this._handleKeyDown}  className="form-control col-2 ml-4" placeholder="Search" />
                            <div className='col-8'></div>
                            {
                                this.state.search.length!=0 && (
                                <button className='float-right btn btn-primary col-1' onClick={()=>this.handleBack()}>Back</button>
                                )
                            }
                            </div>  
                        <div className="mt-3 mb-3">
                            
                        <MDBDataTableV5 paging={false} info={false} searching={false} barReverse striped bordered hover noBottomColumns responsive  data={this.state.datatable} />
                        </div>
                        <div className="ml-3">
                            <Pagination 
                                itemsCount={parseInt(this.state.totalcount)} 
                                pageSize={this.state.pageSize} 
                                onPageChange={this.handlePageChange}
                                currentPage={this.state.currentPage}
                                pageLoad={this.state.paginateLoad}
                            />
                        </div>
                        </div>
                        )

                    }   

                    </div>
                    <PaymentModal/>
                    </div>                   
                </main>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (currentState) => {
    return {

        invoiceDetails: currentState.invoiceDetails,
        isInvoiceChanged: currentState.isInvoiceChanged,
    };
};

const mapActionsToProps = (dispatch) => {
    return {

        showInvoiceModal: (data) => {
            dispatch({ type: 'invoice.modal.open', data : data });
        },
        updateInvoiceComponentFalse: () =>{
            dispatch({type: 'invoice.changed.false'})
        },
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

 
export default connect(mapStateToProps, mapActionsToProps)(AllPaymentComponent);