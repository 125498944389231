import React, { Component } from "react";
import "./../../styles/invoice.css";

import * as service from "./../../Service/service";
import * as jwt_decode from "jwt-decode";
import keys from "./../../Config/config.json";

class InvoiceSummaryComponent extends Component {
  state = {
    slugs: [],
    invoiceDetails: {},
    address: {},
    showData: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      invoiceNumber: props.match.params.invoiceNumber,
    };
    console.log(this.state.invoiceNumber);
  }

  invoiceData = () => {
    service.printInvoice(this.state.invoiceNumber).then(
      (response) => {
        var decoded = jwt_decode(localStorage.keycloak);
        if (JSON.parse(decoded.preferred_username != keys.admin_token)) {
          alert("Unauthorized access");
          localStorage.removeItem("keycloak");
          window.location.href =
            window.location.origin + "?url=" + window.location;
        } else if (response.data.length != 0) {
          var slugs = [];
          response.data.orderInfo.productList.map((item) => {
            slugs.push(item.productSlug);
          });
          var invoiceList = response.data;
          invoiceList.orderInfo.productList = invoiceList.orderInfo.productList.sort(
            (a, b) =>
              a.productId !== b.productId
                ? a.productId < b.productId
                  ? -1
                  : 1
                : 0
          );

          invoiceList.orderInfo.securityDeposit = invoiceList.orderInfo.securityDeposit.sort(
            (a, b) =>
              a.productId !== b.productId
                ? a.productId < b.productId
                  ? -1
                  : 1
                : 0
          );
          console.log(invoiceList);
          this.setState(
            {
              ...this.state,
              slugs: slugs,
              invoiceDetails: invoiceList,
            },
            () => {
              this.mergeProductInfo();
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  mergeProductInfo = () => {
    let items = JSON.parse(JSON.stringify(this.state));

    service.cartProductInfo(this.state.slugs).then(
      (response) => {
        response.data.map((prod) => {
          items.invoiceDetails.orderInfo.productList.map((item) => {
            if (item.productSlug === prod.slug) {
              item.productInfo = prod;
            }
          });
        });

        this.setState(items, () => {
          this.checkAddress();
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  checkAddress = () => {
    service.checkAddress(this.state.invoiceDetails.orderInfo.addressId).then(
      (response) => {
        this.setState(
          {
            ...this.state,
            address: response.data,
            showData: true,
          },
          () => {
            console.log(this.state);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };
  componentWillMount() {
    if (localStorage.hasOwnProperty("keycloak") == false) {
      alert("Login to view");
      window.location.href = window.location.origin + "?url=" + window.location;
    } else if (localStorage.hasOwnProperty("keycloak") == true) {
      this.invoiceData();
    }
  }
  statusColor = (orderStatus) => {
    if (orderStatus) {
      return "text-success ml-3";
    } else {
      return "text-danger ml-3";
    }
  };

  printPage() {
    var printButton = document.getElementById("printpagebutton");

    //Set the button visibility to 'hidden'
    printButton.style.visibility = "hidden";

    //Print the page content
    window.print();

    //Restore button visibility
    printButton.style.visibility = "visible";
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showData && (
          <body class="body p-3 mt-5">
            <div className="float-right">
              <a
                style={{ cursor: "pointer" }}
                id="printpagebutton"
                className="text-primary"
                onClick={() => this.printPage()}
              >
                Click to Print
              </a>
            </div>
            <br />
            <div className="row mt-4">
              <div className="col-12 text-center">
                <img
                  width="180px"
                  src="https://voorent-new.s3.ap-south-1.amazonaws.com/application/voorent.png"
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-6">
                <span style={{ position: "relative", left: "-15px" }}>
                  <span className="d-block font-weight-bold">
                    Voorent Private Limited
                  </span>
                  Basement, 258, Sector 27, <br />
                  Gurugram <br />
                  Haryana 122022 <br />
                  <span>{new Date(this.state.invoiceDetails.invoiceInfo.generatedOn) < new Date('2022-01-01') ? "GSTIN: 06AAFCV6132Q1ZU" : "GSTIN: 06AAFCV6132Q2ZT"}</span>
                </span>

                <div className="row mt-3">
                  <div
                    className="col-11 font-weight-bold"
                    style={{ background: "#DDD", padding: "10px" }}
                  >
                    <span>Generated On :</span>
                    <span>
                      {" "}
                      {new Date(
                        this.state.invoiceDetails.invoiceInfo.generatedOn
                      ).toDateString(
                        this.state.invoiceDetails.invoiceInfo.invoiceNumber
                      )}{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <span style={{ position: "relative", left: "-15px" }}>
                  <span className="font-weight-bold">
                    {this.state.address.name}
                  </span>{" "}
                  <br />
                  {this.state.address.houseNo} {this.state.address.address1}
                  <br />
                  {this.state.address.address2} <br />
                  {this.state.address.city} {this.state.address.pincode}
                  <br />
                  Contact : {this.state.address.contactNo}
                  <br />
                </span>

                <div className="row mt-3">
                  <div
                    className="col-12 font-weight-bold"
                    style={{ background: "#DDD", padding: "10px" }}
                  >
                    <span>Invoice Number :</span>
                    <span>
                      {" "}
                      {this.state.invoiceDetails.invoiceInfo.invoiceNumber}{" "}
                    </span>
                    <span
                      className={this.statusColor(
                        this.state.invoiceDetails.invoiceInfo.isPaid
                      )}
                    >
                      {this.state.invoiceDetails.invoiceInfo.isPaid
                        ? "Paid"
                        : "Unpaid"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div
                className="col-12 font-weight-bold text-center mt-3"
                style={{
                  background:
                    "linear-gradient(270deg, rgb(232, 85, 78), rgb(1, 73, 127))",
                  color: "white",
                  padding: "10px",
                }}
              >
                <span>Your Order Details</span>
              </div>

              <div className="col-12 p-0 mt-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Items</th>
                      <th>Duration</th>
                      <th>Quanity</th>
                      <th>Rent (Excl.GST)</th>
                      <th>SGST</th>
                      <th>CGST</th>
                      <th>Rent (Incl.GST)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.invoiceDetails.orderInfo.productList.map(
                      (item, key) => (
                        <tr>
                          <td>{item.productInfo.name}</td>
                          <td>{item.tenure} Months</td>
                          <td>{item.quantity}</td>
                          <td>
                            Rs.{" "}
                            {(
                              parseFloat(
                                (this.state.invoiceDetails.orderInfo
                                  .securityDeposit[key].rent) 
                              ) / 1.18
                            ).toFixed(2)}
                          </td>
                          <td>
                            Rs.{" "}
                            {(
                              (parseFloat(
                                (this.state.invoiceDetails.orderInfo
                                  .securityDeposit[key].rent)
                              ) /
                                1.18 /
                                100) *
                              9
                            ).toFixed(2)}
                          </td>
                          <td>
                            Rs.{" "}
                            {(
                              (parseFloat(
                                (this.state.invoiceDetails.orderInfo
                                  .securityDeposit[key].rent) 
                              ) /
                                1.18 /
                                100) *
                              9
                            ).toFixed(2)}
                          </td>
                          <td>
                            Rs.{" "}
                            {parseFloat(
                              (this.state.invoiceDetails.orderInfo
                                .securityDeposit[key].rent)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>

              <div className="col-6 offset-6 p-0">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Total Amount</td>
                      <td>
                        Rs.{" "}
                        {(this.state.invoiceDetails.orderInfo.securityDeposit.reduce((totalRent, deposit) => totalRent + deposit.rent, 0)).toFixed(2)
                         }
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">
                        Discount
                        <span className="text-black-50">
                          {" "}
                          ( {this.state.invoiceDetails.orderInfo.couponId} )
                        </span>
                      </td>
                      <td>
                        {" "}
                        Rs. -
                        {this.state.invoiceDetails.orderInfo.orderTerms.discount.toFixed(
                          2
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">
                        Interest
                      </td>
                      <td>
                        {" "}
                        Rs. 
                        {this.state.invoiceDetails.invoiceInfo.interestGenerated.toFixed(
                          2
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Final Amount</td>
                      <td>
                        Rs.{" "}
                        {((this.state.invoiceDetails.orderInfo.securityDeposit.reduce((totalRent, deposit) => totalRent + deposit.rent, 0))+ this.state.invoiceDetails.invoiceInfo.interestGenerated - this.state.invoiceDetails.orderInfo.orderTerms.discount).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mb-5 mt-3">
              <div
                className="col-12 text-black-50"
                style={{ background: "rgb(245, 243, 243)", padding: "12px" }}
              >
                <span>
                  NOTE:<li> We don't include the security deposit paid in the
                  invoice and will be returned to you at the end of your lock-in
                  period.</li>
                  <li>2% interest is charged on invoices pending from more than 30 days.</li>
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                {/* <span className="text-black-50">
                  Kindly pay the amount before due date to avoid late fee
                  charges. Due is 15 days from date of generation of invoice.
                </span> */}
                <table className="m-auto">
                  <tr>
                    <td>
                      <a
                        target="_blank"
                        className="font-weight-bold"
                        style={{ color: "#00487f" }}
                        href="https://voorent.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        className="font-weight-bold"
                        style={{ color: "#00487f" }}
                        href="https://voorent.com/terms-conditions"
                      >
                        Terms And Conditions
                      </a>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        className="font-weight-bold"
                        style={{ color: "#00487f" }}
                        href="https://voorent.com/faq"
                      >
                        FAQ
                      </a>
                    </td>
                  </tr>
                </table>

                <table className="m-auto">
                  <tr>
                    <td>
                      <a href="https://www.facebook.com/Voorent.rentals">
                        <img
                          width="32px"
                          src="https://voorent-new.s3.ap-south-1.amazonaws.com/images/facebook.png"
                        />
                      </a>
                    </td>
                    <td>
                      <a href="https://www.linkedin.com/company/voorent?originalSubdomain=in">
                        <img
                          width="32px"
                          src="https://voorent-new.s3.ap-south-1.amazonaws.com/images/linkedin.png"
                        />
                      </a>
                    </td>
                  </tr>
                </table>

                <span class="display-block">
                  Copyright © 2017 - 2020 Voorent Pvt. Ltd.™ All Rights Reserved
                </span>
                <span class="display-block">
                  Basement, 258, Sector 27, Gurugram, Haryana 122022
                </span>
              </div>
            </div>
          </body>
        )}
      </React.Fragment>
    );
  }
}

export default InvoiceSummaryComponent;
