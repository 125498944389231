import React, { Component } from 'react';
import "./../../../styles/sidebar-style.css";
import MdCloseCircle from 'react-ionicons/lib/MdCloseCircle';

class SideBarComponent extends Component {


    state = {
        current : 'Home'
    }

    navigate = (item) => {
        this.setState({
            ...this.state,
            current : item
        });
        this.closeMenu();
    }

    logout = () => {
        delete localStorage.keycloak;
        window.location.href="./"
    }

    checkActiveNode = (item) => {
        if(this.state.current == item){
            return "active"
        }else{
            return null;
        }
    }


    componentDidMount(){
       // this.openMenu();
    }

    openMenu = () => {
        document.querySelector('#sidebar').classList.add('active');
    }

    closeMenu = () => {
        document.querySelector('#sidebar').classList.remove('active');
    }

    openSubMenu = () => {
        document.querySelector('.collapse').classList.toggle('in');
    }

    render() {
        return (
            <nav id="sidebar">
                <div id="dismiss" onClick={this.closeMenu}>
                    <MdCloseCircle color="#f8f9fa" />
                </div>

                <div className="sidebar-header">
                    <h3>Voorent</h3>
                </div>

                <ul className="list-unstyled components">
                    <li onClick={(e) => {this.props.handleMenuClick('Home'); this.navigate('Home');}} className={this.checkActiveNode('Home')}>
                        <a href="#">Home</a>
                    </li>
                    <li className={this.checkActiveNode('Orders')}>
                        <a href={window.location.origin+'/orders'} >Orders</a>
                    </li>
                    <li className={this.checkActiveNode('Users')}>
                        <a href={window.location.origin+'/users'} >Users</a>
                    </li>
                    <li className={this.checkActiveNode('Invoices')}>
                        <a href={window.location.origin+'/invoices'} >Invoices</a>
                    </li>
                    <li className={this.checkActiveNode('Issues')}>
                        <a href={window.location.origin+'/issues'} >Issues</a>
                    </li>
                    <li className={this.checkActiveNode('Delivered Orders')}>
                        <a href={window.location.origin+'/deliveredOrders'} >Delivered Orders</a>
                    </li>
                    <li className={this.checkActiveNode('Report')}>
                        <a href={window.location.origin+'/reports'} >Report</a>
                    </li>
                    <li className={this.checkActiveNode('Payments')}>
                        <a href={window.location.origin+'/payments'} >Payments</a>
                    </li>
                    <li className={this.checkActiveNode('ProductManagement')}>
                        <a href={window.location.origin+'/addProduct'} >Add Product</a>
                    </li>
                    <li className={this.checkActiveNode('ProductManagement')}>
                        <a href={window.location.origin+'/productManagement'} >Product Management</a>
                    </li>
                
                </ul>

                <ul className="list-unstyled CTAs">
                    <li>
                        <a href="#" className="btn btn-light btn-sm btn-block p-1" onClick={(e) => {this.props.handleMenuClick('Password');this.navigate('Password'); }}>Change Password</a>
                    </li>
                    <li>
                        <a onClick={this.logout} className="article cursor-pointer">Logout</a>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default SideBarComponent;