var APPLICATION_URI = "https://v2.voorent.com";
var dev_uri = "http://3.109.124.1";
var prod_uri = "https://v2.voorent.com";
var ngrok_uri = "https://df64-2401-4900-1c3c-15a8-74ca-f16e-2435-b2fb.ngrok-free.app"
var MS = {};

if (window.location.hostname.indexOf("voorent") != -1) {
  MS = {
    PRODUCT: `${prod_uri}/product`,
    ORDER: `${prod_uri}/order`,
    USER: `${prod_uri}/user`,
    MAILER: `${prod_uri}/mailer`,
    AUTH: `${prod_uri}/auth/admin`,
  };
} else {
  MS = {
    // USER: "http://localhost:9093",
    USER: `${prod_uri}:9093`,
    MAILER: `${prod_uri}:9095`,
    ADMIN: `${prod_uri}/admin`,
    AUTH: `${prod_uri}/auth/admin`,
  };
}

const ENDPOINTS = {
  checkUsername: "/user/checkUsername",
  checkEmail: "/user/checkEmail",
  registerUser: "/user/add",
  passwordReset: "/user/password/update",
  passwordResetMail: "/user/password/reset",
  resetPasswordMailer: "/user/mailer/password/reset",
  cartProductInfo: "/product/cartProductInfo",
  viewAllOrders: "/order/all",
  getOrderByAddressId: "/order/addressId",
  allInvoices: "/invoice/all",
  checkUsername: "/user/checkUsername",
  checkEmail: "/user/checkEmail",
  registerUser: "/user/add",
  userInfo: "/user/userInfo",
  checkAddress: "/address/check",
  changePassword: "/user/changePassword",
  listInvoiceByOrderNumber: "/invoice/orderNumber",
  listPaymentById: "/payment/info",
  orderInfo: "/order/info",
  ordersInfo: "/order/info/all",
  listProcessingOrders: "/order/processed",
  markOrderDelivered: "/order/mark/delivered",
  getAllIssues: "/issues/all",
  submitIssueResponse: "/issues/submit/response",
  submitInvoiceDetails: "/invoice/adjust",
  submitReturnOrderDetails: "/order/mark/returned",
  printInvoice: "/invoice/print",
  isOrderUnpaid: "/invoice/unpaid",
  invoiceMarkPaid: "/invoice/mark/paid",
  listAllUsers: "/user/getAll",
  updateUserPassword: "/user/changePassword/username",
  listAddressesByUsername: "/address/get/username",
  changeOrderAddressId: "/order/update/address",
  sendPendingMail: "/mailer/pending/invoice",
  updateInvoiceMonth: "/invoice/update/month",
  getAllOrders: "/order/csv/all",
  getAllInvoices: "/invoice/csv/all",
  getAllPayments: "/payment/csv/all",
  listAllPayments: "/payment/getAll",
  userByUsername: "/user/getBy/username",
  getSubCategoryByCategory: "/product/getSubCategory",
  addNewProduct: "/product/add/new",
  addNewInvoices: "/invoice/add/new",
  listProductByChoice: "/product/choice",
  updateProductByChoice:"/product/choice/update",
  generateInterest: "/invoice/generateInterest",
  monthlyInterest: "/invoice/monthlyInterest",
  sendNotification: "/mailer/notification",
  listOrderProducts: "/order/getProducts",
  adjustOrderProducts: "/order/adjustProducts",
  allIssues: "/issues/all/issues",
  markOrderCancelled: "/order/mark/cancelled",
  getKycDocuments: "/kyc/getAll",
  uploadDocument : "/kyc/uploadDocument",
  depositAdjust : "/deposit/refund/",
  login : "/login"
};

export function getRoute(key) {
  return `${APPLICATION_URI}/${key}`;
}

export function getRoot(key) {
  return `${APPLICATION_URI}/${key}`;
}

export function getEndpoint(microservice, key) {
  return `${MS[microservice]}${ENDPOINTS[key]}`;
}

export function getLimit() {
  return `${ENDPOINTS["limit"]}`;
}
