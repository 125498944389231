import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';
import * as service from '../../../Service/service';
import './../../../styles/modal-styles.css'

class InvoiceModal extends Component {

    state = {
        operation:'Select operation',
        discount:'',
        amount:'',
        isAdjusting:false,
        showInfo:false,
        invoiceNumber:this.props.invoiceDetails.data.invoiceNumber,
        paymentMethod:'Select Payment Method',
        isDisabled:false,
    }

    onChoiceSelect = (event) => {
        this.setState({
            ...this.state,
            operation: event.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onAmountSelect=(e)=>{
        this.setState({
            ...this.state,
            discount: e.target.value
        }, () => {
            this.getNewAmount()
            console.log(this.state);
        });
    }

    onCommentsEnter=(e)=>{
        this.setState({
            ...this.state,
            amount: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    getNewAmount=()=>{
        this.setState({
            amount:this.props.invoiceDetails.data.invoiceAmount - (this.props.invoiceDetails.data.invoiceAmount*this.state.discount/100)
        })
    }

    onPaymentSelect=(e)=>{
        this.setState({
            ...this.state,
            paymentMethod: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    sendMail=()=>{
    
    this.setState({isDisabled:true})

    service.sendPendingMail(this.props.invoiceDetails.data.invoiceNumber).then((response)=>{
            this.setState({
                showInfo:true,
                isDisabled:false
            })
            if(response.data){
                this.setState({
                    message : 'Mail Sent Successfully',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-success'
                })
                this.props.updateInvoiceComponentTrue();
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-danger'
                })
            }

        }, () => {
            console.log(this.state);
        });
    }

    markInvoicePaid=()=>{
        this.setState({...this.state,isDisabled: true})

        let obj = {
            invoiceNumber:this.props.invoiceDetails.data.invoiceNumber,
            comments:this.state.paymentMethod
        }

        service.invoiceMarkPaid(obj).then((response)=>{
            this.setState({
                ...this.state,
                isDisabled:false,
                showInfo: true,
            });

            if(response.data){
                this.setState({
                    message : 'Mark Paid Successfully',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-success'
                })
                this.props.updateInvoiceComponentTrue();
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-danger'
                })
            }

        })
    }

    updateInvoiceMonth=()=>{

        this.setState({isDisabled:true})

        service.updateInvoiceMonth(this.props.invoiceDetails.data.invoiceNumber).then((response)=>{
            this.setState({
                showInfo:true,
                isDisabled:false
            })
            if(response.data){
                this.setState({
                    message : 'Month Updated Successfully',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-success'
                })
                this.props.updateInvoiceComponentTrue();
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-danger'
                })
            }

        }, () => {
            console.log(this.state);
        });

    }

    adjustInvoice=()=>{
        this.setState({...this.state,isAdjusting: true})

        let obj = {
            invoiceNumber:this.props.invoiceDetails.data.invoiceNumber,
            amount:this.state.amount,
            comments:this.props.invoiceDetails.data.invoiceAmount*this.state.discount/100
        }

        service.submitInvoiceDetails(obj).then((response)=>{
            this.setState({
                ...this.state,
                amount:'',
                discount:'',
                isAdjusting:false,
                showInfo: true,
            });

            if(response.data){
                this.setState({

                    message : 'Invoice Adjusted Successfully',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-success'
                })
                this.props.updateInvoiceComponentTrue();
            }
            if(!response.data){
                this.setState({
                    message : 'Something went wrong',
                    infoType : 'alert alert-dismissible mt-2 fade show alert-danger'
                })
            }

        })
    }

    viewInvoiceRoute=()=>{
        if(this.state.operation=='View Invoice'){
        window.location.href= window.location.origin+"/invoice/summary/"+this.props.invoiceDetails.data.invoiceNumber
        }
        else if(this.state.operation=='Handle Issues'){
            window.location.href= window.location.origin+"/get/issues/"+this.props.invoiceDetails.data.invoiceNumber
        }
        else if(this.state.operation=='Payment Details'){
            window.location.href= window.location.origin+"/view/payments/"+this.props.invoiceDetails.data.paymentId
        }
    }

    componentWillReceiveProps(){
        this.setState({operation:'Select operation',paymentMethod:'Select Payment Method',ordersInfo:false,showInfo:false})    
    }

    render() {
        return (
            <Modal dialogClassName="custom-modal" visible={this.props.invoiceDetails.showModal} onClickBackdrop={this.props.closeInvoiceModal}>
                
                
                <div className="modal-header">
                    <h5 className="modal-title">What do you want to do?</h5>
                </div>
                <div className="modal-body">

                    <div >
                        <div className="alert alert-secondary" role="alert" style={{width:'100%'}}>
                            
                            <b>Invoice Number : {this.props.invoiceDetails.data.invoiceNumber}</b><br/>
                            <b>Invoice Amount : {this.props.invoiceDetails.data.invoiceAmount}</b><br/>
                        </div>
                    
                            <label htmlFor="choice" className="font-weight-bold">Select Operation</label>
                            <select value={this.state.operation} onChange={this.onChoiceSelect}  className="browser-default custom-select" id="choice" placeholder="Select operation" style={{width:'100%'}}>
                                <option>Select operation</option>
                                <option>View Invoice</option>
                                <option>Adjust Invoice</option>
                                {
                                   !this.props.invoiceDetails.data.isPaid && (
                              
                                <option>Remind User</option>
                                   )}
                                <option>Update Invoice Month</option>
                                {
                                    this.props.invoiceDetails.data.paymentId!=0 && (
                                        <option>Payment Details</option>
                                    )
                                 }
                                <option>Handle Issues</option>
                               {
                                   !this.props.invoiceDetails.data.isPaid && (
                                    <option>Mark Paid</option>)
                               }
                            </select>
                            {
                                                this.state.showInfo && (
                                                    <div className={this.state.infoType} role="alert">
                                                        <span>{this.state.message}</span>
                                                    </div>
                                                )
                            }

                            {
                                this.state.operation=='Payment Details' && this.viewInvoiceRoute()
                            }
                            
                            {
                                this.state.operation=='View Invoice' && this.viewInvoiceRoute()
                                
                            }
                            {
                                this.state.operation=='Handle Issues' && this.viewInvoiceRoute()
                                
                            }
                            
                            {
                                this.state.operation=='Adjust Invoice' && (
                                    <div>
                                       
                                            <form>
                                                
                                                <label htmlFor="amount" className="font-weight-bold mt-2">Discount</label><br/>
                                                <input style={{border:'1px solid #D3D3D3',width: '100%'}} onChange={this.onAmountSelect} value={this.state.discount}  placeholder="Discount percent"/> 
                                                <br />
                                                <div className="mt-2">
                                                    <label  className="font-weight-bold">New Amount</label><br/>
                                                    <input style={{border:'1px solid #D3D3D3',width: '100%'}} value={this.state.amount} onChange={this.onCommentsEnter}/>
                                                </div>
                                            </form>
                                                
                                    </div>
                                )
                            }
                            {
                                this.state.operation=='Mark Paid' && (
                                    <div>
                                       
                                            <form>
                                            <label htmlFor="paymentMethod" className="font-weight-bold">Select Operation</label>                     
                                            <select value={this.state.paymentMethod} onChange={this.onPaymentSelect}  className="browser-default custom-select" id="paymentMethod" placeholder="Select payment method">
                                                <option>Select Payment Method</option>
                                                <option>Payment By Cash</option>
                                                <option>Payment By Online Transaction</option>
                                                <option>Adjust by security deposit</option>
                                            </select>
                                            </form>
                                                
                                    </div>
                                )
                            }
                    </div>

                </div>
                <div className="modal-footer">

                    {
                         this.state.operation=='Update Invoice Month' && (
                            <button  type="button" className="btn btn-success" disabled={this.state.isDisabled} onClick={()=> this.updateInvoiceMonth()}>
                                Update month
                            </button>)
                    }
                    {
                         this.state.operation=='Remind User' && (
                            <button  type="button" className="btn btn-success" disabled={this.state.isDisabled} onClick={()=> this.sendMail()}>
                                Send Remainder
                            </button>)
                    }
                    {
                        this.state.operation=='Adjust Invoice' && (
                    <button  type="button" className="btn btn-success" disabled={this.state.isAdjusting} onClick={()=> this.adjustInvoice()}>
                        Adjust
                    </button>)
                    }
                    {
                        this.state.operation=='Mark Paid' && (
                    <button  type="button" className="btn btn-success" disabled={this.state.isDisabled} onClick={()=> this.markInvoicePaid()}>
                        Mark Paid
                    </button>)
                    }
                    <button type="button" className="btn btn-primary" onClick={this.props.closeInvoiceModal}>
                        Close
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        invoiceDetails: currentState.invoiceDetails,
        isInvoiceChanged: currentState.isInvoiceChanged,
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeInvoiceModal: () => {
            dispatch({ type: 'invoice.modal.close' })
        },
        updateInvoiceComponentTrue: () =>{
            dispatch({type: 'invoice.changed.true'})
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceModal);