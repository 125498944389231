import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';

import * as service from '../../../Service/service';


class OrderModal extends Component {


    render() {
        return (
            <Modal visible={this.props.orderDetails.showModal} onClickBackdrop={this.props.closeOrderModal}>
                <div className="modal-header">
                    <h5 className="modal-title">What do you want to do?</h5>
                </div>
                <div className="modal-body">

                    <div className="col-12">
                        <div className="alert alert-secondary" role="alert">
                            
                                <b>Order Number : {this.props.orderDetails.data.orderNumber}</b><br/>
                                <b>Username : {this.props.orderDetails.data.username}</b>
                            
                        </div>
                        <div>
                        <table>
                            <tbody>
                            <tr>
                                <td><b>Order Details</b></td>
                                <td><a className='text-primary' style={{cursor:'pointer'}} href={window.location.origin+"/view/order/"+this.props.orderDetails.data.orderNumber}>View Order</a></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><b>Invoice Details</b></td>
                                <td><a className='text-primary' style={{cursor:'pointer'}} href={window.location.origin+"/view/invoices/"+this.props.orderDetails.data.orderNumber}>View Invoices</a></td>
                            </tr>
                            
                            <tr>
                            <td><b>Issue Response</b></td>
                                <td><a className='text-primary' style={{cursor:'pointer'}} href={window.location.origin+"/get/issues/"+this.props.orderDetails.data.orderNumber}>Issues</a></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><b>Order Update</b></td>
                                <td><a className='text-primary' style={{cursor:'pointer'}} href={window.location.origin+"/mark/"+this.props.orderDetails.data.orderNumber+"/"+this.props.orderDetails.data.username+"/"+this.props.orderDetails.data.orderStatus}>Delivered/Returned/Address</a></td>            
                            </tr>


                            {
                            this.props.orderDetails.data.paymentId!=0 && (
                            <tr>
                                <td><b>Payment Details</b></td>
                                <td><a className='text-primary' style={{cursor:'pointer'}} href={window.location.origin+"/view/payments/"+this.props.orderDetails.data.paymentId}>View Payments</a></td>
                            </tr>)
                            }
                            </tbody>
                        </table>

                        </div>
                  
                    </div>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={this.props.closeOrderModal}>
                        Close
                </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        orderDetails: currentState.orderDetails
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeOrderModal: () => {
            dispatch({ type: 'order.modal.close' })
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(OrderModal);